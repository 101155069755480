import { doc, getDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MyContext } from "../../context/MyProvider";
import { db } from "../../firebase";
import { CircularProgress } from "@mui/material";
import { useOutletContext } from "react-router-dom";

import "firebase/auth";
// import Avatar from '../../components/Avatar/Avatar.jsx';
import FullCardLayout from "./FullCardLayout copy.jsx";
import Banner from "./Banner";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const IndividualCard = () => {
  /* const { user, setTitle } = useOutletContext(); */
  let { id } = useParams();
  /* setTitle(`Card ID: ${id}`); */

  const context = useContext(MyContext);
  const navigate = useNavigate();
  let [cardData, setCardData] = useState();

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const docRef = doc(db, "cards", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCardData({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.error("Document does not exist");
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCards();
  }, [id]);

  return (
    <>
      {cardData ? (
        <>
          <FullCardLayout {...cardData} />
          <Banner />
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default IndividualCard;
