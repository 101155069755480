import * as React from "react";
import { SvgIcon as MuiSvgIcon, styled } from "@mui/material";
import useMediaQuery from "src/hooks/useMediaQuery";

const SvgIcon = styled(MuiSvgIcon, {
  name: "BigPlus",
  shouldForwardProp: (prop) => prop !== "mobileView",
})(({ theme, mobileView }) => ({
  stroke: theme.palette.primary.main,
  strokeWidth: "2px",
  strokeLinecap: "round",
  fontSize: "15rem",
  ...(mobileView && { fontSize: "10rem" }),
}));

SvgIcon.defaultProps = {
  viewBox: "0 0 700 600",
  focusable: "false",
  "aria-hidden": "true",
};

const BigPlus = (props) => {
  const mobileView = useMediaQuery(0, "480px");
  return (
    <SvgIcon mobileView={mobileView} {...props}>
      <path d="m349.3 39.023c-2.8867 0.36328-5.0156 2.8711-4.9023 5.7773v229.6h-229.6c-0.35156-0.03125-0.70312-0.03125-1.0508 0-3.0938 0.29297-5.3633 3.0352-5.0742 6.125 0.28906 3.0938 3.0312 5.3672 6.125 5.0781h229.6v229.6c-0.019532 1.5 0.5625 2.9453 1.6133 4.0117 1.0508 1.0664 2.4883 1.668 3.9883 1.668s2.9375-0.60156 3.9883-1.668c1.0508-1.0664 1.6328-2.5117 1.6133-4.0117v-229.6h229.6c1.5 0.019532 2.9453-0.5625 4.0117-1.6133 1.0664-1.0508 1.668-2.4883 1.668-3.9883s-0.60156-2.9375-1.668-3.9883c-1.0664-1.0508-2.5117-1.6328-4.0117-1.6133h-229.6v-229.6c0.0625-1.6445-0.60156-3.2344-1.8125-4.3477-1.2148-1.1133-2.8555-1.6367-4.4883-1.4297z" />
    </SvgIcon>
  );
};

export default BigPlus;
