import MyProvider from "./context/MyProvider.js";

//!imported the new context which handles storing the authentication
import { useEffect, useState } from "react";
//! import the useLocalStorage hook
import { useLocalStorage } from "./hooks/useLocalStorage";

import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Route, Routes, useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import BackgrdImgGenPage from "./pages/Background_Image_Generator_Page/BackgrdImgGenPage.jsx";
import CardsPage from "./pages/Cards_Page/CardsPage.jsx";
import CreateNewCard from "./pages/Cards_Page/CreateNewCard copy.jsx";
import EditCard from "./pages/Cards_Page/EditCard copy.jsx";
import FullCardLayout from "./pages/Cards_Page/FullCardLayout.jsx";
import IndividualCardPage from "./pages/Cards_Page/IndividualCardPage";
import Contacts from "./pages/Contacts_Page/Contacts.jsx";
import ViewInsideGroup from "./pages/Contacts_Page/ViewInsideGroup.jsx";
import ViewContact from "./pages/Contacts_Page/ViewContact.jsx";
import EmailGen from "./pages/Email_Generator_Page/EmailGen.jsx";
import Error from "./pages/Error.jsx";
import LPFeatures from "./pages/Landing_Page/Features_Section/LPFeatures.jsx";
import LandingPage from "./pages/Landing_Page/LandingPage.jsx";
import LayoutTestPage from "./pages/LayoutTestPage/LayoutTestPage.jsx";
import LoginPage from "./pages/Login_Page/LoginPage.jsx";
import OrderCard from "./pages/Order_Physical_Card_Page/OrderCard.jsx";
import OrderCardDesignSelect from "./pages/Order_Physical_Card_Page/OrderCardDesignSelect.jsx";
import Settings from "./pages/Settings_Page/Settings.jsx";
import SignUpPage from "./pages/Sign_Up_Page/SignUpPage.jsx";
import TermsConditions from "./pages/Terms_and_Conditions/TermsConditions.jsx";
import ContactCardDesign from "./pages/View_Contact_Card_Page/ContactCardDesign.jsx";
import PasswordReset from "./pages/Login_Page/PasswordReset.jsx";
import { AdminRoute, ProtectedRoute } from "./utils/ProtectedRoute.jsx";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import AdminPage from "./pages/Admin/AdminPage";
import ThemeProvider from "./themes/ThemeProvider";
import ProtectedLayout from "./Layout/ProtectedLayout.jsx";
import PaymentResponsePage from "./pages/PaymentResponsePage/index.jsx";

function App() {
  const navigate = useNavigate();
  // const context = useContext(MyContext);
  //! deconstruct the object stored in local storage
  const [storedValue, setValue] = useLocalStorage("authToken", null);
  const [user, loading, error] = useAuthState(auth); // eslint-disable-line

  return (
    <ThemeProvider>
      <MyProvider>
        <Routes>
          {/* ---------- VISITOR ROUTES ----------*/}
          <Route path="/" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/log-in" element={<LoginPage />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/card/:id" element={<IndividualCardPage />} />
          {/*---------- USER ROUTES ----------*/}
          <Route
            element={
              <ProtectedLayout
                user={user}
                loading={loading}
                redirectPath={"/log-in"}
              />
            }
          >
            <Route path="/cards" element={<CardsPage />} title="TEST" />
            <Route path="/cards/newcard" element={<CreateNewCard />} />
            <Route path="/cards/:id" element={<IndividualCardPage />} />
            <Route path="/cards/:id/edit" element={<EditCard mode="edit" />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/contacts/:id" element={<ViewInsideGroup />} />
            <Route path="/contacts/:id/:contactid" element={<ViewContact />} />
            <Route path="/backgrounds" element={<BackgrdImgGenPage />} />
            <Route path="/email-signatures" element={<EmailGen />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/order-card" element={<OrderCard />} />
            {/* PAYMENT ROUTE */}
            <Route path="/payment" element={<PaymentResponsePage />} />
            <Route
              path="/order/card/:CrdOrdId/:CardID"
              element={<OrderCardDesignSelect />}
            />
            <Route
              path="/order/card/:CrdOrdId"
              element={<OrderCardDesignSelect />}
            />
          </Route>

          {/*---------- ADMIN ROUTES ----------*/}
          <Route
            element={
              <AdminRoute
                user={user}
                loading={loading}
                redirectPath={"/log-in"}
              />
            }
          >
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/admin2" element={<AdminPage />} />
          </Route>

          {/*---------- ADDITIONAL ROUTES ----------*/}
          <Route path="/features" element={<LPFeatures />} />
          <Route path="/termsandconditions" element={<TermsConditions />} />
          <Route path="/error" element={<Error />} />
          <Route path="/contact-card" element={<ContactCardDesign />} />
          <Route path="/layout" element={<LayoutTestPage />} />
          <Route path="/exportcard" element={<FullCardLayout />} />
        </Routes>
      </MyProvider>
    </ThemeProvider>
  );
}

export default App;
