import { alpha, createTheme, lighten, darken } from "@mui/material";
import { experimental_sx as sx } from "@mui/material/styles";

// const themeColors = {
//   primary: "#25DAC5",

//   secondary: "#FFFFFF",

//   title: "#1E0E62",

//   header: "#333333",

//   subtitle: "#A1A1B0",

//   black: "#000000",

//   white: "#ffffff",

//   settings: "#1A202C",

//   grey: "#545454",
// };

const themeColors = {
  primary: "#16C48A",

  secondary: "#34455E",

  title: "#1E0E62",

  header: "#333333",

  subtitle: "#A1A1B0",

  black: "#000000",

  white: "#ffffff",

  settings: "#1A202C",

  grey: "#545454",

  lightblue: "#27a8e0",

  yellow: "#ffab38",

  dark: "#254441",
};

const colors = {
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white,
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      100: themeColors.black,
    },
  },
  primary: {
    lighter: {
      10: lighten(themeColors.primary, 0.1),
      20: lighten(themeColors.primary, 0.2),
      30: lighten(themeColors.primary, 0.3),
      40: lighten(themeColors.primary, 0.4),
      50: lighten(themeColors.primary, 0.5),
      60: lighten(themeColors.primary, 0.6),
      70: lighten(themeColors.primary, 0.7),
      80: lighten(themeColors.primary, 0.8),
      90: lighten(themeColors.primary, 0.9),
    },
    main: themeColors.primary,
    darker: {
      10: darken(themeColors.primary, 0.1),
      20: darken(themeColors.primary, 0.2),
      30: darken(themeColors.primary, 0.3),
      40: darken(themeColors.primary, 0.4),
      50: darken(themeColors.primary, 0.5),
      60: darken(themeColors.primary, 0.6),
      70: darken(themeColors.primary, 0.7),
      80: darken(themeColors.primary, 0.8),
      90: darken(themeColors.primary, 0.9),
    },
  },
  shadows: {
    primary:
      "0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)",
    card: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  gradients: {},
};

export const defaultLight = createTheme({
  palette: {
    primary: {
      light: colors.primary.lighter[50],
      main: colors.primary.main,
      dark: colors.primary.darker[50],
    },
    white: themeColors.white,
  },
  colors: {
    shadows: { primary: colors.shadows.primary },
    alpha: {
      white: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white,
      },
      black: {
        5: alpha(themeColors.black, 0.02),
        10: alpha(themeColors.black, 0.1),
        30: alpha(themeColors.black, 0.3),
        50: alpha(themeColors.black, 0.5),
        70: alpha(themeColors.black, 0.7),
        100: themeColors.black,
      },
    },
    primary: {
      lighter: {
        10: lighten(themeColors.primary, 0.1),
        20: lighten(themeColors.primary, 0.2),
        30: lighten(themeColors.primary, 0.3),
        40: lighten(themeColors.primary, 0.4),
        50: lighten(themeColors.primary, 0.5),
        60: lighten(themeColors.primary, 0.6),
        70: lighten(themeColors.primary, 0.7),
        80: lighten(themeColors.primary, 0.8),
        90: lighten(themeColors.primary, 0.9),
      },
      main: themeColors.primary,
      darker: {
        10: darken(themeColors.primary, 0.1),
        20: darken(themeColors.primary, 0.2),
        30: darken(themeColors.primary, 0.3),
        40: darken(themeColors.primary, 0.4),
        50: darken(themeColors.primary, 0.5),
        60: darken(themeColors.primary, 0.6),
        70: darken(themeColors.primary, 0.7),
        80: darken(themeColors.primary, 0.8),
        90: darken(themeColors.primary, 0.9),
      },
    },
  },
  spacing: 9,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1840,
    },
  },
  typography: {
    paragraph: {
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "1.1vw",
      lineHeight: "18px",
      letterSpacing: "0.25px",
      color: themeColors.grey,
      ["@media (max-width:480px)"]: {
        fontSize: "1.2rem",
      },
      ["@media (min-width:480px) and (max-width:1280px)"]: {
        fontSize: "1.6rem",
      },
    },
    h6: {
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "1.6rem",
      lineHeight: "22px",
      letterSpacing: "0.15px",
      color: themeColors.settings,
    },
    h5: {
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "1.3vw",
      lineHeight: "22px",
      letterSpacing: "0.15px",
      color: themeColors.settings,
      ["@media (max-width:480px)"]: {
        fontSize: "1.6rem",
      },
      ["@media (min-width:480px) and (max-width:1280px)"]: {
        fontSize: "1.7rem",
      },
    },
    h4: {
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "1.7vw",
      lineHeight: "32px",
      letterSpacing: "0.25px",
      color: themeColors.settings,
      margin: "1rem",
      ["@media (max-width:480px)"]: {
        fontSize: "2.1rem",
        margin: "0.5rem 1rem 0.5rem 1rem",
      },
      ["@media (min-width:480px) and (max-width:1280px)"]: {
        fontSize: "2.1rem",
      },
    },
    h3: {
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "2vw",
      ["@media (min-width:1200px)"]: {
        fontSize: "2.2rem",
      },
      lineHeight: "24px",
      letterSpacing: "0.55px",
      color: themeColors.title,
      margin: "0.5rem 0rem 1rem 0rem",
      ["@media (max-width:480px)"]: {
        fontSize: "1rem",
      },
    },
    h2: {
      fontFamily: "Raleway",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "3.2rem",
      lineHeight: "32px",
      letterSpacing: "0.25px",
      color: themeColors.header,
      ["@media (max-width:480px)"]: {
        fontSize: "1.8rem",
      },
    },
    h1: {
      fontFamily: "Nunito Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "4rem",
      textAlign: "center",
      lineHeight: "3rem",
      letterSpacing: "0.15px",
      color: themeColors.header,
      // ["@media (max-width:480px)"]: {
      //   fontSize: "1.6rem",
      // },
      // ["@media (min-width:480px) and (max-width:1280px)"]: {
      //   fontSize: "1.7rem",
      // },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: sx({
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          textTransform: "none",
          fontFamily: "Nunito Sans",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "1.3vw",
          lineHeight: "22px",
          letterSpacing: "0.15px",
          color: themeColors.settings,
          padding: "0",
          minWidth: "0",
          textAlign: "left",
          ["@media (max-width:480px)"]: {
            fontSize: "1.4rem",
          },
          ["&:hover"]: {
            backgroundColor: colors.primary.dark,
          },
        }),
        outlined: sx({
          color: colors.primary.main,
          padding: "0.5rem 1.4rem",
          border: `1px solid ${colors.alpha.black[10]}`,
          textTransform: "uppercase",
          fontFamily: "Nunito Sans",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "1vw",
          lineHeight: "24px",
          textAlign: "center",
          letterSpacing: "1.25px",
        }),
        contained: sx({
          color: themeColors.white,
          padding: "0.5rem 1.4rem",
          border: `1px solid ${colors.alpha.black[10]}`,
          textTransform: "uppercase",
          fontFamily: "Nunito Sans",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "1vw",
          lineHeight: "24px",
          textAlign: "center",
          letterSpacing: "1.25px",
        }),
      },
      variants: [
        {
          props: { variant: "upgrade" },
          style: {
            height: "100%",
            fontFamily: "Nunito Sans",
            fontStyle: "normal",
            fontWeight: "800",
            fontSize: "1vw",
            lineHeight: "17px",
            textAlign: "center",
            letterSpacing: "1.25px",
            textTransform: "uppercase",
            color: colors.primary.main,
            ["@media (max-width:360px)"]: {
              textAlign: "right",
              fontSize: "0.9rem",
            },
            ["@media (min-width:480px) and (max-width:1280px)"]: {
              fontSize: "1.2rem",
            },
          },
        },
        {
          props: { variant: "login" },
          style: {
            padding: "1rem 2rem",
            boxShadow: colors.shadows.card,
            borderRadius: "4px",
            borderColor: "transparent",
            backgroundColor: colors.primary.main,
            color: colors.alpha.white[100],
            fontSize: "1.8rem",
            textTransform: "uppercase",
            justifySelf: "flex-end",
            cursor: "pointer",
            ["&:hover"]: {
              color: colors.primary.main,
              fontWeight: "bold",
            },
          },
        },
        {
          props: { variant: "setting-link" },
          style: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            ["@media (max-width:480px)"]: {
              fontSize: "1.1rem",
              margin: "0.5rem 1rem 0.5rem 1rem",
            },
            ["@media (min-width:480px) and (max-width:1280px)"]: {
              margin: "1rem 0rem ",
            },
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        root: sx({
          fontFamily: "Nunito Sans",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "1.2rem",
          lineHeight: "18px",
          letterSpacing: "0.15px",
          paddingTop: "3px",
          paddingLeft: "6px",
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: sx({
          fontFamily: "Nunito Sans",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "1.2rem",
          lineHeight: "18px",
          letterSpacing: "0.15px",
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: sx({
          ["& fieldset"]: {
            border: "0px solid black",
            borderRadius: "50px",
            boxShadow: colors.shadows.card,
            margin: 0.5,
          },
        }),
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "greenborder-column" },
          style: {
            border: `5px solid ${colors.primary.main}`,
            boxShadow: colors.shadows.card,
            borderRadius: "10px",
            fontFamily: "Nunito Sans",
            margin: "0.5rem",
            boxSizing: " border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          },
        },
        {
          props: { variant: "no-border" },
          style: {
            margin: "0.5rem",
            boxSizing: " border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "flex-start",
            width: "100%",
          },
        },
        {
          props: { variant: "greenborder-row" },
          style: {
            border: `2px solid ${colors.primary.main}`,
            boxShadow: colors.shadows.card,
            borderRadius: "10px",
            fontFamily: "Nunito Sans",
            margin: "0.5rem",
            boxSizing: " border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            padding: "1rem 3rem 2rem 1rem",
          },
        },
      ],
    },
    MuiCardActionArea: {
      variants: [
        {
          props: { variant: "listitem" },
          style: {
            border: "1px solid transparent",
            borderRadius: "8px",
            margin: "0.5rem",
            boxSizing: " border-box",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "flex-start",
            width: "100%",
          },
        },
      ],
    },
    MuiCardContent: {
      variants: [
        {
          props: { variant: "cardPage" },
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0",
          },
        },
      ],
    },
    MuiGrid: {
      styleOverrides: {
        item: sx({
          display: "flex",
          flexDirection: "column",
        }),
        container: sx({
          overflow: "hidden",
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: sx({
          margin: "0",
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: sx({
          margin: "1vw",
        }),
      },
    },
    MuiDrawer: {
      styleOverrides: {
        docked: sx({
          ["@media (max-width:480px)"]: {
            width: "100vw",
          },
        }),
        paper: sx({
          ["@media (max-width:480px)"]: {
            width: "100vw",
          },
        }),
      },
    },
    // MuiInput: {
    //   styleOverrides: {
    //     disabled: sx({
    //       color: "rgba(0,0,0,1)",
    //       WebkitTextFillColor: "rgba(0,0,0,1)",
    //     }),
    //   },
    // },
    // MuiInputBase: {
    //   styleOverrides: {
    //     disabled: sx({
    //       color: "rgba(0,0,0,1)",
    //       WebkitTextFillColor: "rgba(0,0,0,1)",
    //     }),
    //     root: sx({
    //       color: "rgba(0,0,0,1)",
    //       WebkitTextFillColor: "rgba(0,0,0,1)",
    //     }),
    //   },
    // },
    MuiContainer: {
      styleOverrides: {
        root: sx({
          mx: "0",
          width: "100vw",
          ["@media (min-width:480px)"]: {
            padding: "2rem 4rem 2rem 4rem !important",
            maxWidth: "calc(100vw - 6.5rem) !important",
          },
        }),
      },
      variants: [
        {
          props: { variant: "pageHeader" },
          style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "2rem 4rem 2rem 4rem",
            ["@media (max-width:480px)"]: {
              padding: "1rem 2rem 1rem 2rem",
            },
            justifyContent: "space-between",
          },
        },
        {
          props: { variant: "scroll" },
          style: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflowY: "scroll",
            scrollbarWidth: "none",
            ["&::-webkit-scrollbar"]: {
              display: "none",
            },
          },
        },
      ],
    },
  },
});
