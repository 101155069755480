import { AppBar, Toolbar, Button } from "@mui/material";

const Banner = () => {
  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0, height: 65 }}
    >
      <Toolbar
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Button
          onClick={() => {
            window.location.href = "https://www.trowit.com";
          }}
          sx={{ color: "white" }}
        >
          Get your NFC card
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Banner;
