import SideNavBar from "../../components/Side_NavBar/SideNavBar";
import Layout from "../../Layout/Layout";

const BackgrdImgGenPage = () => {
  return (
    <Layout title="Backgrounds">
      <SideNavBar />
      <div style={{ color: "orange" }}>Generate your backgrounds here</div>
    </Layout>
  );
};

export default BackgrdImgGenPage;
