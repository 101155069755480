import { useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import AvatarComponent from "../components/AvatarComponent/AvatarComponent";
import SideNavBar from "../components/Side_NavBar/SideNavBar";

import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Grid, Container, Typography, Box } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const Layout = (props) => {
  const [user, loading, error] = useAuthState(auth); // eslint-disable-line
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const mobileView = useMediaQuery(0, "480px");

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Grid container sx={{ width: "100vw", height: "100vh" }}>
      {mobileView ? (
        sidebarOpen ? (
          <SideNavBar
            mobile={true}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        ) : null
      ) : (
        <Grid item sx={{ width: "6.5rem", height: "100vh" }}>
          <SideNavBar />
        </Grid>
      )}
      <Grid item sx={{ flexGrow: "1", height: "100vh" }}>
        <Container variant="pageHeader">
          {mobileView ? (
            sidebarOpen ? (
              <CloseRoundedIcon
                sx={{ fontSize: "8vw" }}
                onClick={handleSidebarToggle}
              />
            ) : (
              <MenuRoundedIcon
                sx={{ fontSize: "8vw" }}
                onClick={handleSidebarToggle}
              />
            )
          ) : null}

          <Typography variant="h2">{props.title}</Typography>
          <AvatarComponent user={user} />
        </Container>
        {props.extraControls !== undefined && (
          <Container variant="pageHeader">
            {/* //! sticky navigation here */}
            {props.extraControls}
          </Container>
        )}
        <Container variant="scroll" sx={{ flexGrow: "1" }}>
          {props.children}
        </Container>
      </Grid>
    </Grid>
  );
};

export default Layout;
