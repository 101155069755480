import Layout from "../../Layout/Layout";

const EmailGen = () => {
  return (
    <Layout title="Email Signature Generator">
      <div style={{ color: "gray" }}>Generate your email signatures here</div>
    </Layout>
  );
};

export default EmailGen;
