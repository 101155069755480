import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Fab,
  Tooltip,
  Box,
  Typography,
  Link,
  Dialog,
  AppBar,
  Toolbar,
} from "@mui/material";
import avatarPlaceholderImg from "../../images/cards_page/avatar.png";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import VCard from "vcard-creator";
// import cardWork from '../../images/cards_page/card_work@2x.png';
// import cardPersonal from '../../images/cards_page/card_personal@2x.png';
// import createCard from '../../images/cards_page/create_card@2x.png';
import { db, auth } from "src/firebase";
import { addDoc, collection, setDoc, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { CardInformationData } from "./CardInformationData";
import { MyContext } from "../../context/MyProvider";
import { useContext, useState } from "react";
import OcSelectCard from "./SelectCard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "src/hooks/useMediaQuery";

const FullCardLayout = (cardData) => {
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const context = useContext(MyContext);
  const theme = useTheme();
  const mobileView = useMediaQuery(0, "480px");
  // Define a new vCard
  const vcard = new VCard();
  vcard
    // Add personal data
    .addName(cardData.fields.firstName?.value, cardData.fields.lastName?.value)
    // Add work data
    .addCompany(cardData.fields.company?.value)
    .addJobtitle(cardData.fields.title?.value)
    .addRole(cardData.fields.department?.value)
    .addEmail(cardData.fields.email?.value)
    .addPhoneNumber(cardData.fields.phone?.value, "PREF;WORK")
    .addAddress(cardData.fields.address?.value)
    .addPhoto(cardData.fields.profile, "JPEG");
  var data = new Blob([vcard], { type: "text/vcard" });
  var vcardDownload = window.URL.createObjectURL(data);

  const handleAddContact = async () => {
    // addDoc(collection(db, `contacts/${user.uid}/All Contacts`), {
    //   ...cardData,
    //   created: Date(),
    // });

    try {
      await setDoc(
        doc(db, `contacts/${user.uid}/All Contacts/${cardData.id}`),
        {
          ...cardData,
          created: Date.now(),
          groups: ["All Contacts"],
        }
      );
    } catch (err) {
      console.error(err);
    }
    // if (doc(db, `contacts/${user.uid}`).exists(1))
    try {
      const groups = await getDoc(doc(db, `contacts/${user.uid}`));
      if (!groups?.exists()) {
        await setDoc(
          doc(db, `contacts/${user.uid}`),
          { groups: ["All Contacts"] },
          { merge: true }
        );
      } else if (!groups?.data().groups.includes("All Contacts")) {
        const currentGroups = groups?.data().groups;
        currentGroups.unshift("All Contacts");
        await setDoc(
          doc(db, `contacts/${user.uid}`),
          { groups: currentGroups },
          { merge: true }
        );
      }
    } catch (err) {
      console.error(err);
    }
    navigate("/contacts");
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        {cardData ? (
          <>
            <Card
              variant="borderless"
              sx={{
                borderColor: cardData?.cardColor || theme.palette.primary.main,
                maxWidth: "480px",
                mb: "10rem",
                ...(mobileView && { minWidth: "100vw", mb: "10rem" }),
              }}
            >
              <Grid
                container
                sx={{
                  position: "relative",
                  backgroundColor:
                    cardData?.cardColor || theme.palette.primary.main,
                  minWidth: 350,
                  minHeight: 230,
                  overflow: "visible",
                  marginBottom: "40%",
                }}
              >
                {user && user?.uid != cardData.uid ? (
                  <Tooltip
                    sx={{ fontSize: "5rem" }}
                    title="Add Contact on Trowit"
                    arrow
                  >
                    <Fab
                      aria-label={"add contact"}
                      color={theme.palette.secondary.main}
                      sx={{ position: "absolute", left: "80%", top: "5%" }}
                    >
                      <PersonAddIcon
                        fontSize="large"
                        onClick={() => {
                          setOpen(true);
                        }}
                      />
                    </Fab>
                  </Tooltip>
                ) : null}
                {/* LOGO IMAGE */}
                {cardData?.logo && (
                  <CardMedia
                    sx={{
                      maxHeight: "45px",
                      minHeight: "45px",
                      objectFit: "contain",
                      position: "absolute",
                      top: "10%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: 0,
                      right: 0,
                      textAlign: "center",
                    }}
                    component="img"
                    image={cardData.logo}
                    alt="logo image"
                  />
                )}

                {/* PROFILE IMAGE */}
                <CardMedia
                  sx={{
                    borderRadius: "50%",
                    maxWidth: 275,
                    maxHeight: 275,
                    minWidth: 275,
                    minHeight: 275,
                    position: "absolute",
                    top: "35%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    textAlign: "center",
                  }}
                  component="img"
                  image={
                    cardData?.profile ? cardData.profile : avatarPlaceholderImg
                  }
                  alt="profile image"
                  width={350}
                  height={350}
                />
              </Grid>
              <CardContent sx={{ width: "100%", justifyContent: "center" }}>
                <Typography
                  variant="h1"
                  sx={{ mb: 2, textAlign: "center" }}
                >{`${cardData?.fields?.firstName?.value} ${cardData?.fields?.lastName?.value}`}</Typography>
                {cardData?.fields?.title && (
                  <Typography
                    sx={{ textAlign: "center" }}
                    variant="h5"
                  >{`${cardData?.fields?.title.value}`}</Typography>
                )}

                {cardData?.fields?.company && (
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >{`${cardData?.fields?.company.value}`}</Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // flexWrap: "wrap",
                    mt: 2,
                    // maxWidth: 350
                  }}
                >
                  {cardData?.fieldOrder
                    ?.filter(
                      (field) =>
                        field === "email" ||
                        field === "phone" ||
                        field === "website"
                    )
                    .map((field, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              minWidth: "35px",
                              minHeight: "35px",
                              bgcolor: theme.palette.primary.main,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "10px",
                            }}
                          >
                            {CardInformationData(context, "white")[field].svg}
                          </Box>
                          {field === "website" && (
                            <a
                              style={{
                                fontFamily: "Nunito Sans",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "1.6rem",
                                lineHeight: "18px",
                                color: "#545454",
                                lineHeight: "18px",
                                letterSpacing: "0.25px",
                              }}
                              target="_blank"
                              href={cardData?.fields[field]?.value}
                            >
                              {cardData?.fields[field]?.value}
                            </a>
                          )}
                          {field === "email" || field === "phone" ? (
                            <Typography
                              sx={{
                                fontFamily: "Nunito Sans",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "18px",
                                letterSpacing: "0.25px",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                fontSize: "1.6rem",
                              }}
                            >
                              {cardData?.fields[field]?.value}
                            </Typography>
                          ) : null}
                        </Box>
                      );
                    })}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    {cardData?.fieldOrder
                      ?.filter(
                        (field) =>
                          field === "link" ||
                          field === "twitter" ||
                          field === "instagram" ||
                          field === "linkedin" ||
                          field === "facebook" ||
                          field === "youtube" ||
                          field === "whatsapp"
                      )
                      .map((field, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: "8px",
                            }}
                          >
                            <a
                              href={
                                field === "whatsapp"
                                  ? `https://wa.me/${cardData?.fields[field]?.value}`
                                  : `${cardData?.fields[field]?.value}`
                              }
                              target="_blank"
                            >
                              <Box
                                sx={{
                                  minWidth: "35px",
                                  minHeight: "35px",
                                  bgcolor: theme.palette.primary.main,
                                  borderRadius: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                                // onClick={() => {
                                //   window.location.href =
                                //     cardData?.fields[field]?.value;
                                // }}
                              >
                                {
                                  CardInformationData(context, "white")[field]
                                    .svg
                                }
                              </Box>
                            </a>
                          </Box>
                        );
                      })}
                  </Box>
                  {cardData?.fields["documents"] && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: "35px",
                          minHeight: "35px",
                          maxWidth: "35px",
                          maxHeight: "35px",
                          bgcolor: "black",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "10px",
                        }}
                      >
                        {CardInformationData(context, "white")["documents"].svg}
                      </Box>
                      <a
                        style={{
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "18px",
                          letterSpacing: "0.25px",
                          fontSize: "1.6rem",
                          color: "#545454",
                        }}
                        href={cardData?.fields["documents"].url}
                        download
                        target="_blank"
                      >
                        {cardData?.fields["documents"].value}
                      </a>
                    </Box>
                  )}
                </Box>
              </CardContent>
              {/* <CardActions>
                <Button
                  sx={{ m: "auto" }}
                  variant="outlined"
                  component={Link}
                  startIcon={<PersonAddIcon />}
                  href={vcardDownload}
                  download={`${cardData.fields.firstName?.value}-${cardData.fields.lastName?.value}.vcf`}
                >
                  Download Contact
                </Button>
              </CardActions> */}
            </Card>
            <AppBar
              position="fixed"
              color="white"
              sx={{ top: "auto", bottom: 65 }}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  component={Link}
                  startIcon={<DownloadRoundedIcon />}
                  href={vcardDownload}
                  download={`${cardData.fields.firstName?.value}-${cardData.fields.lastName?.value}.vcf`}
                  sx={{
                    border: "0",
                    "&:hover": {
                      border: "0",
                      backgroundColor: "white",
                    },
                  }}
                >
                  Download Contact
                </Button>
              </Toolbar>
            </AppBar>
          </>
        ) : null}
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <OcSelectCard handleAddContact={handleAddContact} cardData={cardData} />
      </Dialog>
    </>
  );
};

export default FullCardLayout;
