import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AvatarComponent from "../components/AvatarComponent/AvatarComponent";
import SideNavBar from "../components/Side_NavBar/SideNavBar";
import useMediaQuery from "src/hooks/useMediaQuery";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { Grid, Typography } from "@mui/material";

const ProtectedLayout = ({ user, loading, redirectPath }) => {
  const [title, setTitle] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const mobileView = useMediaQuery(0, "480px");
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  if (loading) return <CircularProgress />;
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  } else
    return (
      <>
        {/* NEW--------------------------------------- */}
        <Grid container sx={{ width: "100vw", height: "100vh" }}>
          {mobileView ? (
            sidebarOpen ? (
              <SideNavBar
                mobile={true}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
              />
            ) : null
          ) : (
            <Grid item sx={{ width: "6.5rem", height: "100vh" }}>
              <SideNavBar />
            </Grid>
          )}
          <Grid item sx={{ flexGrow: "1", height: "100vh" }}>
            <Container variant="pageHeader">
              {mobileView ? (
                sidebarOpen ? (
                  <CloseRoundedIcon
                    sx={{ fontSize: "8vw" }}
                    onClick={handleSidebarToggle}
                  />
                ) : (
                  <MenuRoundedIcon
                    sx={{ fontSize: "8vw" }}
                    onClick={handleSidebarToggle}
                  />
                )
              ) : null}

              <Typography
                variant="h2"
                sx={{ width: "100%", textAlign: "left", ml: "6px" }}
              >
                {title}
              </Typography>
              <AvatarComponent user={user} />
            </Container>
            {mobileView && (
              <hr
                color="lightgrey"
                size="1px"
                style={{ marginBottom: "3px" }}
              />
            )}

            {/* {props.extraControls !== undefined && (
							<Container variant="pageHeader">
								{props.extraControls}
							</Container>
						)} */}
            <Container
              variant="scroll"
              sx={{
                flexGrow: "1",
                ...(mobileView && {
                  pl: "8px",
                }),
              }}
            >
              <Outlet context={{ user, setTitle }} />
            </Container>
          </Grid>
        </Grid>
      </>
    );
};

export default ProtectedLayout;
