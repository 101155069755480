import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute = ({ user, loading, redirectPath }) => {
	if (loading) return <CircularProgress />;
	if (!user) {
		return <Navigate to={redirectPath} replace />;
	} else return <Outlet context={user} />;
};

export const AdminRoute = ({ user, loading, redirectPath, children }) => {
	const [loadingAdmin, setLoadingAdmin] = useState(true);
	const [isAdmin, setIsAdmin] = useState(false);
	useEffect(() => {
		const fetchClaims = async () => {
			const {
				claims: { admin },
			} = await user.getIdTokenResult();
			setIsAdmin(admin === true);
			setLoadingAdmin(false);
		};
		if (user) fetchClaims();
	}, [user, loading]);

	if (loading || loadingAdmin) return <CircularProgress />;
	if (!user || !isAdmin) {
		return <Navigate to={redirectPath} replace />;
	} else return children ? children : <Outlet />;
};
