import { Box } from "@mui/material";
const PaymentResponsePage = () => {
  const { search } = window.location;
  const isSuccess = new URLSearchParams(search).get("success");
  const isCanceled = new URLSearchParams(search).get("canceled");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignContent: "stretch",
        flexWrap: "wrap",
        // height: "85vh",
        overflowY: "scroll",
        scrollbarWidth: "none",
        ["&::-webkit-scrollbar"]: {
          display: "none",
        },
      }}
    >
      {/* TODO: Make this look not shitty */}
      Payment response: {isSuccess ? "succesfull" : "canceled"}
    </Box>
  );
};
export default PaymentResponsePage;
