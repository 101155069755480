// import { useState } from "react";
import { ThemeProvider } from "@mui/material";
import { defaultLight } from "./schemes/light";
// import { themeCreator } from "./ThemeMap";

const ThemeProviderWrapper = function (props) {
  // Below takes/sets the selected theme from localStorage. This should be used once multiple themes are created and the user can choose
  //   const currentThemeName = localStorage.getItem("trowitTheme") || "defaultLight";
  //   const [themeName, setThemeName] = useState(currentThemeName);
  //   const theme = themeCreator(themeName);
  //   const saveThemeName = (themeName) => {
  //     localStorage.setItem("trowitTheme", themeName);
  //     setThemeName(themeName);
  //   };

  // For now, I've set the default theme directly in the provider. If above is used, it should be theme={theme} and the 'saveThemeName' can be passed as a prop to be called using 'useContext''
  return <ThemeProvider theme={defaultLight}>{props.children}</ThemeProvider>;
};

export default ThemeProviderWrapper;
