import React, { useEffect } from "react";
// import trowit_logo_img from "../../images/landing_page/logo_small.svg";
import trowit_logo_img from "../../images/logos/Asset_1.png";
import google_sign_up from "../../images/landing_page/google_sign_in.png";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from "../../firebase";
import { Link } from "react-router-dom";
import { Box, Typography, Button, TextField } from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const LoginPage = () => {
  // eslint-disable-next-line
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/cards");
  }, [user, loading]);

  const ValidationSchema = Yup.object().shape({
    Email: Yup.string().email().required("Required field"),
    Password: Yup.string()
      .min(6, "Minimum 6 characters")
      .max(16, "Maximum 16 characters")
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
      //   "Must contain one uppercase letter, one lowercase letter, one number and one special character."
      // )
      .matches(
        /^(?=.*[!\"#\$%&\'()\*+\,-\.\/:;\<\=\>?@\[\]\^_\`\{\|\}~])/,
        "Must contain a special character."
      )
      .required("Required field"),
  });

  const formik = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      logInWithEmailAndPassword(values.Email, values.Password);
    },
  });

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100vw", display: "flex", justifyContent: "center" }}>
        <img
          src={trowit_logo_img}
          style={{
            height: "8rem",
            marginBlock: "4rem",
          }}
          alt="Trowit logo"
        />
      </Box>
      <Box
        sx={{
          width: "100vw",
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">Log in to your account</Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            name="Email"
            label="Email"
            type="text"
            variant="outlined"
            value={formik.values.Email}
            onChange={formik.handleChange}
            error={formik.touched.Email && Boolean(formik.errors.Email)}
            helperText={(formik.touched.Email && formik.errors.Email) || " "}
            onBlur={formik.handleBlur}
            sx={{ mt: 4, mb: 1.5, width: "20rem" }}
          />

          <TextField
            name="Password"
            label="Password"
            type="password"
            autoComplete="off"
            variant="outlined"
            onChange={formik.handleChange}
            error={formik.touched.Password && Boolean(formik.errors.Password)}
            helperText={
              (formik.touched.Password && formik.errors.Password) || " "
            }
            onBlur={formik.handleBlur}
            sx={{ mb: 1.5, width: "20rem" }}
          />

          <Button variant="login" type="submit" sx={{ mb: 1.5, mt: 0.5 }}>
            Submit
          </Button>
        </Box>
        <Link
          style={{ fontSize: "1.7rem", marginBottom: "2rem" }}
          to="/sign-up"
        >
          Create a new Account
        </Link>

        <Link
          style={{ fontSize: "1.7rem", marginBottom: "2rem" }}
          to="/password-reset"
        >
          Forgot password?
        </Link>

        <Button
          onClick={(e) => {
            e.preventDefault();
            signInWithGoogle();
          }}
        >
          <img
            src={google_sign_up}
            style={{
              height: "4rem",
            }}
            alt="Google Sign-up button"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPage;
