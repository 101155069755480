import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Card } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { useContext } from "react";
import { MyContext } from "../../context/MyProvider";
import { OrderCardTemplateDets } from "./CardData.js";

const OderCardTemplate = (props) => {
  let location = useLocation();
  let navigate = useNavigate();
  const { CrdOrdId } = useParams();
  const context = useContext(MyContext);
  const selectedCard = context.selectedCardForOrder;
  const [cardDetails, setCardDetails] = useState([]);

  useEffect(() => {
    setCardDetails([
      OrderCardTemplateDets[0],
      OrderCardTemplateDets[1],
      OrderCardTemplateDets[2],
    ]);
  }, []);

  const handleUpgradeClick = (type) => {
    // console.log(type);
    //  context.setCrdOrderType(type);
    if (type === "Basic") {
      //  console.log("Clicked Basic");
      if (selectedCard) {
        navigate(`/order/card/Customized/${selectedCard.id}`);
      } else {
        navigate(`/order/card/Customized`);
      }
    } else if (type === "Customized") {
      // console.log("Clicked Customized");
      if (selectedCard) {
        navigate(`/order/card/Premium/${selectedCard.id}`);
      } else {
        navigate(`/order/card/Premium`);
      }
    }
  };
  const handleDowngradeClick = (type) => {
    // console.log(type);
    //  context.setCrdOrderType(type);
    if (type === "Customized") {
      //  console.log("Clicked Basic");
      if (selectedCard) {
        navigate(`/order/card/Basic/${selectedCard.id}`);
      } else {
        navigate(`/order/card/Basic`);
      }
    } else if (type === "Premium") {
      // console.log("Clicked Customized");
      if (selectedCard) {
        navigate(`/order/card/Customized/${selectedCard.id}`);
      } else {
        navigate(`/order/card/Customized`);
      }
    }
  };

  return (
    <>
      {cardDetails
        .filter((element) => {
          if (CrdOrdId) {
            return element.header === CrdOrdId;
          } else {
            return element.header;
          }
        })
        .map((element, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="OC__sub-container3__price-table-and-buttons"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "2rem",
                transform: "scale(0.8)",
              }}
            >
              {CrdOrdId ? (
                CrdOrdId === "Customized" || CrdOrdId === "Premium" ? (
                  <>
                    <div
                      className="OC__sub-container3__price-frame"
                      style={{
                        transform: "scale(0.6) translateX(40px)",
                        opacity: 0.7,
                      }}
                    >
                      <h3 className="OC__sub-container3___header">
                        {CrdOrdId === "Customized"
                          ? cardDetails[0].header
                          : cardDetails[1].header}
                      </h3>
                      <p className="OC__sub-container3__price-frame__cta">
                        {CrdOrdId === "Customized"
                          ? cardDetails[0].cta
                          : cardDetails[1].cta}
                      </p>
                      <ul className="OC__sub-container3__price-frame__benefits">
                        <li className="OC__sub-container3__price-frame__single-benefit"></li>
                        {CrdOrdId === "Customized"
                          ? cardDetails[0].benefits.map((element, index) => (
                              <li
                                key={index}
                                className="OC__sub-container3__price-frame__single-benefit"
                              >
                                {element}
                              </li>
                            ))
                          : cardDetails[1].benefits.map((element, index) => (
                              <li
                                key={index}
                                className="OC__sub-container3__price-frame__single-benefit"
                              >
                                {element}
                              </li>
                            ))}
                      </ul>
                      <div className="OC__sub-container3__price-and-fees">
                        {CrdOrdId === "Customized" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <span className="OC__sub-container3__discountprice">
                              {cardDetails[0].price}
                            </span>
                            <span className="OC__sub-container3__price">
                              <span>{" "}</span>
                              {cardDetails[0].discountedPrice}
                            </span>
                          </div>
                        ) : (
                          <h4 className="OC__sub-container3__price">
                            {cardDetails[0].price}
                          </h4>
                        )}

                        <p className="OC__sub-container3__fee">
                          +{" "}
                          {CrdOrdId === "Customized"
                            ? cardDetails[0].fee
                            : cardDetails[1].fee}
                        </p>
                      </div>
                    </div>
                    <div>
                      <ArrowBackIosRoundedIcon
                        sx={{
                          fontSize: 40,
                          cursor: "pointer",
                          ml: "-40px",
                        }}
                        onClick={() => handleDowngradeClick(element.header)}
                      />
                    </div>
                  </>
                ) : (
                  <div style={{ width: "32rem" }}></div>
                )
              ) : null}
              <div
                className="OC__sub-container3__price-frame"
                style={{
                  marginLeft: "-30px",
                  marginRight: "-30px",
                  transform: "scale(0.9)",
                }}
              >
                <h3 className="OC__sub-container3___header">
                  {element.header}
                </h3>
                <p className="OC__sub-container3__price-frame__cta">
                  {element.cta}
                </p>
                <ul className="OC__sub-container3__price-frame__benefits">
                  <li className="OC__sub-container3__price-frame__single-benefit"></li>
                  {element.benefits.map((element, index) => (
                    <li
                      key={index}
                      className="OC__sub-container3__price-frame__single-benefit"
                    >
                      {element}
                    </li>
                  ))}
                </ul>
                <div className="OC__sub-container3__price-and-fees">
                  {element.header === "Basic" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      {/* <span className="OC__sub-container3__discountprice">
                        {element.price}
                      </span> */}
                      <span className="OC__sub-container3__price">
                        <span>{" "}</span>
                        {element.price}
                      </span>
                    </div>
                  ) : (
                    <h4 className="OC__sub-container3__price">
                      {element.price}
                    </h4>
                  )}
                  <p className="OC__sub-container3__fee">+ {element.fee}</p>
                </div>
              </div>

              {CrdOrdId ? (
                CrdOrdId === "Customized" || CrdOrdId === "Basic" ? (
                  <>
                    <div>
                      <ArrowForwardIosRoundedIcon
                        sx={{ fontSize: 40, cursor: "pointer", mr: "-40px" }}
                        onClick={() => handleUpgradeClick(element.header)}
                      />
                    </div>
                    <div
                      className="OC__sub-container3__price-frame"
                      style={{
                        transform: "scale(0.6) translateX(-40px)",
                        opacity: 0.7,
                      }}
                    >
                      <h3 className="OC__sub-container3___header">
                        {CrdOrdId === "Customized"
                          ? cardDetails[2].header
                          : cardDetails[1].header}
                      </h3>
                      <p className="OC__sub-container3__price-frame__cta">
                        {CrdOrdId === "Customized"
                          ? cardDetails[2].cta
                          : cardDetails[1].cta}
                      </p>
                      <ul className="OC__sub-container3__price-frame__benefits">
                        <li className="OC__sub-container3__price-frame__single-benefit"></li>
                        {CrdOrdId === "Customized"
                          ? cardDetails[2].benefits.map((element, index) => (
                              <li
                                key={index}
                                className="OC__sub-container3__price-frame__single-benefit"
                              >
                                {element}
                              </li>
                            ))
                          : cardDetails[1].benefits.map((element, index) => (
                              <li
                                key={index}
                                className="OC__sub-container3__price-frame__single-benefit"
                              >
                                {element}
                              </li>
                            ))}
                      </ul>
                      <div className="OC__sub-container3__price-and-fees">
                        <h4 className="OC__sub-container3__price">
                          {CrdOrdId === "Customized"
                            ? cardDetails[2].price
                            : cardDetails[1].price}
                        </h4>
                        <p className="OC__sub-container3__fee">
                          +{" "}
                          {CrdOrdId === "Customized"
                            ? cardDetails[2].fee
                            : cardDetails[2].fee}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div style={{ width: "32rem" }}></div>
                )
              ) : null}
            </div>
            {!CrdOrdId && (
              <button
                onClick={() => props.action(element.header)}
                className="OC__sub-container3__button"
              >
                ORDER
              </button>
            )}
          </div>
        ))}
    </>
  );
};

export default OderCardTemplate;
