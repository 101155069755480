import React, { useContext, useState, useEffect } from "react";
import { MyContext } from "../../context/MyProvider";
// import Card from "../Cards_Page/Card";
import workImg from "../../images/cards_page/card_work@2x.png";
import {
  Box,
  Card,
  Typography,
  CardMedia,
  CardActionArea,
  CardContent,
} from "@mui/material";

import { collection, getDocs, query, where } from "firebase/firestore";
import { useOutletContext, useParams } from "react-router-dom";
import { db } from "src/firebase";
import avatarPlaceholderImg from "src/images/cards_page/avatar.png";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const OcSelectCard = () => {
  const context = useContext(MyContext);
  let userId = context.userId;
  const selectedCard = context.selectedCardForOrder;
  const setSelectedCard = context.setSelectedCardForOrder;
  const mobileView = useMediaQuery(0, "480px");
  const theme = useTheme();

  const { CrdOrdId, CardID } = useParams();

  const { user } = useOutletContext();
  const [cards, setCards] = useState([]);
  // let [selectedCardId, setSelectedCardId] = useState();
  //   const [selectedCard, setSelectedCard] = useState(null);

  const fetchCards = async () => {
    let cardsArray = [];
    try {
      const cardsQuery = await getDocs(
        query(collection(db, "cards"), where("uid", "==", user?.uid))
      );
      cardsQuery.forEach((doc) => {
        cardsArray.push({ id: doc.id, ...doc.data() });
      });
      setCards(cardsArray);
      if (CardID) {
        setSelectedCard(
          cardsArray.find((card) => {
            return card.id === CardID;
          })
        );
      } else if (cardsArray.length >= 1) {
        setSelectedCard(cardsArray[0]);
      }
    } catch (err) {
      console.error(err); // eslint-disable-line
      console.log("An error occured while fetching cards"); // eslint-disable-line
    }
  };

  useEffect(() => {
    if (user) fetchCards();
  }, [user]);

  //Todo need to add a condition so when the card is unselected it will turn the value to none

  const handleCardSelectClickOC = (id) => {
    // console.log(`Selected card id: ${id}`);
    // context.setSelectedCardId(id);
    let index = cards.findIndex((e) => e.id === id);
    // console.log(index);
    setSelectedCard(cards[index]);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "2rem",
        padding: "0.6rem",
        mb: "1rem",
        // flexWrap: "wrap",
        // minWidth: "380px",
        // maxWidth: "100vw",

        width: "85vw",
        ...(CrdOrdId && { width: "35rem" }),
        overflowX: "auto",
        scrollbarWidth: "thin",
        ["&::-webkit-scrollbar"]: {
          display: "none",
        },
        ...(mobileView && { width: "95vw" }),
      }}
    >
      {cards.length >= 1 ? (
        cards.map((card, index) => (
          <Card
            key={`oc_card_${index}`}
            variant="greenborder-column"
            sx={{
              minHeight: "250px",
              minWidth: "187px",
              borderColor: card.cardColor || theme.palette.primary.main,
              justifyContent: "space-between",
              overflow: "hidden",
              position: "relative",
              mr: "2rem",
              mb: "1rem",
              ...(card === selectedCard && {
                boxShadow: "0px 0px 8px 10px rgba(22, 196, 138, 0.5)",
              }),
              ...(mobileView && {
                minHeight: "180px",
                minWidth: "135px",
                mr: "0rem",
              }),
              ...(mobileView && CrdOrdId
                ? {
                    minHeight: "120px",
                    minWidth: "85px",
                    mr: "0rem",
                  }
                : null),
            }}
          >
            <CardActionArea onClick={() => handleCardSelectClickOC(card.id)}>
              <CardMedia
                component="img"
                image={card.profile ?? avatarPlaceholderImg}
                sx={{
                  height: "178px",
                  width: "187px",
                  display: "flex",
                  ...(mobileView && { height: "128px", width: "135px" }),
                  ...(mobileView && CrdOrdId
                    ? {
                        height: "90px",
                        width: "85px",
                        mr: "0rem",
                      }
                    : null),
                }}
              />
              <CardContent sx={{ ...(mobileView && { padding: 0.5 }) }}>
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    ...(mobileView && { fontSize: "3.2vw" }),
                  }}
                  pt={1}
                >
                  {card.cardTitle.value ? card.cardTitle.value : "Unnamed Card"}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))
      ) : (
        <Card
          variant="greenborder-column"
          sx={{
            height: "250px",
            width: "187px",
            borderColor: "lightgrey",
            justifyContent: "center",
            overflow: "hidden",
            position: "relative",
            mr: "2rem",
            mb: "1rem",
            px: "2px",
            ...(mobileView && {
              height: "180px",
              width: "135px",
              mr: "0rem",
            }),
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              ...(mobileView && { fontSize: "3.2vw" }),
            }}
            pt={1}
          >
            You have no cards. Please create one to order your physical Trowit
            card.
          </Typography>
        </Card>
      )}
    </Box>
  );
};

export default OcSelectCard;
