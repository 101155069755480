import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  EmailAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
  updateEmail,
  updatePassword,
  deleteUser,
  reauthenticateWithCredential,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  deleteDoc,
  doc,
  setDoc,
  updateDoc
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import firebaseConfig from "./firebaseConfig";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: "select_account",
});
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (/* name, */ email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    return res.user.uid;

    /* ADD USER ACTUAL NAME TO DB */
    /* await addDoc(collection(db, "users"), {
			uid: user.uid,
			name,
			authProvider: "local",
			email,
		}); */
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const updateNameAndPic = async (user, name, picLink) => {

  const userId = user.uid

  try {
    await updateProfile(user, { displayName: name, photoURL: picLink });
    const userRef = doc(db, "users", userId);

    await updateDoc(userRef, {
      name: name,
      profilePictureUrl: picLink
    });

  } catch (err) {
    console.error(err);
  }
};

const changeEmail = async (user, email, currentWord) => {
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    currentWord
  );
  try {
    await reauthenticateWithCredential(auth.currentUser, credential);
    console.log("password correct");
  } catch (err) {
    console.error(err);
    console.log("password not correct");
  }
  try {
    await updateEmail(user, email);
  } catch (err) {
    console.error(err);
  }
};

const changePassword = async (user, newWord, currentWord) => {
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    currentWord
  );

  try {
    await reauthenticateWithCredential(auth.currentUser, credential);
    console.log("password correct");
  } catch (err) {
    console.error(err);
    console.log("password not correct");
  }
  try {
    await updatePassword(user, newWord);
    alert("password reset");
  } catch (err) {
    console.error(err);
  }
};
const deleteAccount = async (user) => {
  try {
    await deleteUser(user);
  } catch (err) {
    console.error(err);
  }
};

const deleteCard = async (cardData) => {
  try {
    const cardRef = doc(db, "cards", cardData.id);
    await deleteDoc(cardRef);
  } catch (err) {
    console.error(err);
  }
};

const passwordResetEmail = async (email) => {
  const actionCodeSettings = {
    url: `https://trowit-4326b.firebaseapp.com/log-in?email=${email}`,
  };
  try {
    await sendPasswordResetEmail(auth, email, actionCodeSettings);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const creatHubspotLink = async (hsID, userID, email) => {
  const accountCreationDT = new Date()
  try {
    await setDoc(doc(db, "users", `${userID}`), {
      accountType: 'free',
      accountCreationDateTime: accountCreationDT,
      email: email,
      uid: userID,
      hubspot: hsID,
    });

    console.log(`Account for user ${userID}, created successfully.`)
  } catch (err) {
    console.error(err);
  }
};

const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  storage,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  logout,
  updateNameAndPic,
  changeEmail,
  changePassword,
  deleteAccount,
  deleteCard,
  passwordResetEmail,
  creatHubspotLink,
};
