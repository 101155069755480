import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  CardMedia,
  CardActionArea,
  CardContent,
  Button,
} from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db, auth } from "src/firebase";
import avatarPlaceholderImg from "src/images/cards_page/avatar.png";
import { useTheme } from "@mui/material/styles";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const OcSelectCard = ({ handleAddContact, cardData }) => {
  const [selectedCard, setSelectedCard] = useState();
  const mobile = window.matchMedia("(max-width: 480px)");
  const theme = useTheme();

  const [user] = useAuthState(auth);
  const [cards, setCards] = useState([]);

  const fetchCards = async () => {
    let cardsArray = [];
    try {
      const cardsQuery = await getDocs(
        query(collection(db, "cards"), where("uid", "==", user?.uid))
      );
      cardsQuery.forEach((doc) => {
        cardsArray.push({ id: doc.id, ...doc.data() });
      });
      setCards(cardsArray);
    } catch (err) {
      console.error(err); // eslint-disable-line
      console.log("An error occured while fetching cards"); // eslint-disable-line
    }
  };

  useEffect(() => {
    if (user) fetchCards();
  }, [user]);

  const handleConnect = async () => {
    try {
      const exists = await getDoc(doc(db, `contacts/${cardData.uid}`));
      if (!exists.exists()) {
        await setDoc(doc(db, `contacts/${cardData.uid}`), {
          groups: ["All Contacts"],
        });
      } else if (
        exists.exists() &&
        !exists.data().groups.includes("All Contacts")
      ) {
        const currentGroups = exists.data().groups;
        currentGroups.unshift("All Contacts");
        await setDoc(
          doc(db, `contacts/${cardData.uid}`),
          { groups: currentGroups },
          { merge: true }
        );
      }
      await setDoc(
        doc(db, `contacts/${cardData.uid}/All Contacts/${selectedCard.id}`),
        {
          ...selectedCard,
          created: Date.now(),
          groups: ["All Contacts"],
        }
      );
    } catch (err) {
      console.error(err);
    }
    // try {
    //   const groups = await getDoc(doc(db, `contacts/${cardData.uid}`));
    //   console.log(groups.data());
    //   if (!groups.data().groups) {
    //     await setDoc(
    //       doc(db, `contacts/${cardData.uid}`),
    //       { groups: ["All Contacts"] },
    //       { merge: true }
    //     );
    //   } else if (!groups.data().groups.includes("All Contacts")) {
    //     const currentGroups = groups.data().groups;
    //     currentGroups.unshift("All Contacts");
    //     await setDoc(
    //       doc(db, `contacts/${cardData.uid}`),
    //       { groups: currentGroups },
    //       { merge: true }
    //     );
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
    handleAddContact();
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          flexWrap: "wrap",
          minWidth: "480px",
          maxWidth: "100vw",
        }}
      >
        <Typography variant="h4">
          Select which one of your cards to connect:
        </Typography>
        {cards
          ? cards.map((card, index) => (
              <Card
                key={`oc_card_${index}`}
                variant="greenborder-column"
                sx={{
                  height: "250px",
                  width: "187px",
                  // minHeight: "250px",
                  // minWidth: "187px",
                  borderColor: card.cardColor || theme.palette.primary.main,
                  justifyContent: "space-between",
                  overflow: "hidden",
                  position: "relative",
                  m: "1rem",
                  ...(card === selectedCard && {
                    boxShadow: "0px 0px 8px 10px rgba(22, 196, 138, 0.5)",
                  }),
                  ...(mobile.matches && { height: "180px" }),
                  ...(mobile.matches && { width: "135px" }),
                }}
              >
                <CardActionArea onClick={() => setSelectedCard(card)}>
                  <CardMedia
                    component="img"
                    image={card.profile ?? avatarPlaceholderImg}
                    sx={{
                      height: "178px",
                      width: "187px",
                      display: "flex",
                      ...(mobile.matches && { height: "128px" }),
                      ...(mobile.matches && { width: "135px" }),
                    }}
                  />
                  <CardContent sx={{ ...(mobile.matches && { padding: 0.5 }) }}>
                    <Typography
                      variant="h6"
                      sx={{ textAlign: "center" }}
                      pt={1}
                    >
                      {card.cardTitle ? card.cardTitle.value : "Unnamed Card"}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))
          : null}
      </Box>
      <Button
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "40%",
          margin: "auto",
          my: "2rem",
        }}
        variant="contained"
        disabled={!selectedCard}
        onClick={() => {
          handleConnect();
        }}
      >
        Connect
      </Button>
    </Box>
  );
};

export default OcSelectCard;
