export const CardInformationData = (context, color) => ({
  firstName: {
    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "First Name",
    value: context.crtCard.first_name,
  },
  lastName: {
    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Last Name",
    value: context.crtCard.last_name,
  },
  title: {
    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Title",
    value: context.crtCard.title,
  },
  company: {
    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Company",
    value: context.crtCard.company,
  },
  department: {
    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Department",
    value: context.crtCard.department,
  },
  phone: {
    svg: (
      <svg
        width="12"
        height="12"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.23 12.2602L13.69 11.9702C13.08 11.9002 12.48 12.1102 12.05 12.5402L10.21 14.3802C7.38 12.9402 5.06004 10.6302 3.62004 7.79024L5.47004 5.94025C5.90004 5.51025 6.11004 4.91025 6.04004 4.30025L5.75004 1.78025C5.63004 0.770254 4.78004 0.0102539 3.76004 0.0102539H2.03004C0.900041 0.0102539 -0.0399593 0.950254 0.0300407 2.08025C0.560041 10.6202 7.39 17.4402 15.92 17.9702C17.05 18.0402 17.99 17.1002 17.99 15.9702V14.2402C18 13.2302 17.24 12.3802 16.23 12.2602Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Phone",
    value: context.crtCard.phone,
  },
  email: {
    svg: (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM17.6 4.25L10.53 8.67C10.21 8.87 9.79 8.87 9.47 8.67L2.4 4.25C2.15 4.09 2 3.82 2 3.53C2 2.86 2.73 2.46 3.3 2.81L10 7L16.7 2.81C17.27 2.46 18 2.86 18 3.53C18 3.82 17.85 4.09 17.6 4.25Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Email",
    value: context.crtCard.email,
  },
  address: {
    svg: (
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.841797 6.2625C0.841797 2.80023 3.64203 0 7.1043 0C10.5666 0 13.3668 2.80023 13.3668 6.2625C13.3668 10.9594 7.1043 17.8928 7.1043 17.8928C7.1043 17.8928 0.841797 10.9594 0.841797 6.2625ZM4.8675 6.26252C4.8675 7.4971 5.8695 8.4991 7.1041 8.4991C8.3387 8.4991 9.3407 7.4971 9.3407 6.26252C9.3407 5.02791 8.3387 4.02591 7.1041 4.02591C5.8695 4.02591 4.8675 5.02791 4.8675 6.26252Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Address",
    value: context.crtCard.address,
  },
  website: {
    svg: (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H18C19.1 0 20 0.9 20 2V14C20 15.1 19.1 16 18 16H2C0.9 16 0 15.1 0 14L0.00999999 2C0.00999999 0.9 0.9 0 2 0ZM2.00087 14H13.0009V10H2.00087V14ZM13.0009 9H2.00087V5H13.0009V9ZM13.9996 14H17.9996V5H13.9996V14Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Website",
    value: context.crtCard.website,
  },
  link: {
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.3297 4.02026C15.2265 2.12346 18.3018 2.12346 20.1986 4.02026C22.0954 5.91706 22.0954 8.99238 20.1986 10.8892L16.9851 14.1027C15.0883 15.9995 12.013 15.9995 10.1162 14.1027C9.7256 13.7122 9.7256 13.079 10.1162 12.6885C10.5067 12.298 11.1398 12.298 11.5304 12.6885C12.6461 13.8043 14.4551 13.8043 15.5709 12.6885L18.7844 9.47497C19.9002 8.35922 19.9002 6.55022 18.7844 5.43447C17.6687 4.31872 15.8597 4.31872 14.7439 5.43447L12.7071 7.47128C12.3166 7.8618 11.6834 7.8618 11.2929 7.47128C10.9024 7.08075 10.9024 6.44759 11.2929 6.05706L13.3297 4.02026Z"
          fill={color ? color : "black"}
        />
        <path
          d="M10.8892 20.1979C8.99238 22.0947 5.91706 22.0947 4.02026 20.1979C2.12346 18.3011 2.12346 15.2258 4.02026 13.329L7.2338 10.1154C9.1306 8.21869 12.2059 8.21868 14.1027 10.1154C14.4932 10.506 14.4932 11.1391 14.1027 11.5297C13.7122 11.9202 13.079 11.9202 12.6885 11.5297C11.5727 10.4139 9.76377 10.4139 8.64801 11.5297L5.43447 14.7432C4.31872 15.859 4.31872 17.6679 5.43447 18.7837C6.55022 19.8995 8.35922 19.8995 9.47497 18.7837L11.4658 16.7928C11.8564 16.4023 12.4895 16.4023 12.8801 16.7928C13.2706 17.1833 13.2706 17.8165 12.8801 18.207L10.8892 20.1979Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Link",
    value: context.crtCard.link,
  },
  // pdf: {
  //   svg: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <g clipPath="url(#clip0_0_1)">
  //         <path
  //           d="M17.2549 0.124167L22.2204 5.08968C22.2602 5.12663 22.2918 5.17149 22.3131 5.22137C22.3345 5.27126 22.3452 5.32506 22.3446 5.37933V22.4028C22.3424 22.8257 22.1734 23.2307 21.8743 23.5298C21.5753 23.8288 21.1703 23.9978 20.7473 24H4.9073C4.48435 23.9978 4.07935 23.8288 3.78028 23.5298C3.48121 23.2307 3.31223 22.8257 3.31006 22.4028V20.2759C3.31006 20.1661 3.35365 20.0609 3.43125 19.9833C3.50885 19.9057 3.6141 19.8621 3.72385 19.8621C3.83359 19.8621 3.93884 19.9057 4.01644 19.9833C4.09405 20.0609 4.13764 20.1661 4.13764 20.2759V22.4028C4.13764 22.6069 4.21873 22.8027 4.36307 22.947C4.50741 23.0913 4.70317 23.1724 4.9073 23.1724H20.7473C20.9514 23.1724 21.1472 23.0913 21.2915 22.947C21.4359 22.8027 21.517 22.6069 21.517 22.4028V5.79313H16.9653C16.8555 5.79313 16.7503 5.74953 16.6727 5.67193C16.5951 5.59433 16.5515 5.48908 16.5515 5.37933V0.827614H4.9073C4.70317 0.827614 4.50741 0.908702 4.36307 1.05304C4.21873 1.19738 4.13764 1.39314 4.13764 1.59727V9.51726C4.13764 9.627 4.09405 9.73225 4.01644 9.80986C3.93884 9.88746 3.83359 9.93105 3.72385 9.93105C3.6141 9.93105 3.50885 9.88746 3.43125 9.80986C3.35365 9.73225 3.31006 9.627 3.31006 9.51726V1.59727C3.31223 1.17432 3.48121 0.769323 3.78028 0.470252C4.07935 0.171182 4.48435 0.00220346 4.9073 2.89026e-05H16.9653C17.0195 -0.000632687 17.0733 0.0100704 17.1232 0.0314498C17.1731 0.0528292 17.218 0.0844117 17.2549 0.124167ZM20.9294 4.96554L17.379 1.4152V4.96554H20.9294Z"
  //           fill={color ? color : "black"}
  //         />
  //         <path
  //           d="M5.85128 13.3987H5.10645V15.1283H5.93404C6.08047 15.1472 6.22921 15.1364 6.37138 15.0965C6.51356 15.0567 6.64627 14.9887 6.76162 14.8966C6.85003 14.8073 6.91973 14.7013 6.96662 14.5848C7.01352 14.4683 7.03667 14.3436 7.03473 14.218C7.04557 14.0966 7.02544 13.9745 6.97623 13.863C6.92702 13.7516 6.85033 13.6545 6.75335 13.5807C6.47591 13.436 6.16313 13.3728 5.85128 13.3987Z"
  //           fill={color ? color : "black"}
  //         />
  //         <path
  //           d="M9.33545 13.415H10.0637C11.1727 13.415 11.7602 13.9033 11.7851 14.8964C11.7985 15.0988 11.7685 15.3017 11.6971 15.4915C11.6258 15.6813 11.5147 15.8537 11.3713 15.9971C11.0281 16.2657 10.5979 16.3983 10.163 16.3695H9.33545V13.415Z"
  //           fill={color ? color : "black"}
  //         />
  //         <path
  //           d="M19.0346 12.0995V17.694C19.0357 17.8703 19.0017 18.0452 18.9348 18.2083C18.8678 18.3715 18.7691 18.5197 18.6443 18.6444C18.5196 18.7691 18.3714 18.8678 18.2082 18.9348C18.0451 19.0018 17.8702 19.0357 17.6939 19.0346H2.99599C2.81962 19.0357 2.64479 19.0018 2.48163 18.9348C2.31847 18.8678 2.17024 18.7691 2.04553 18.6444C1.92081 18.5197 1.8221 18.3715 1.75511 18.2083C1.68813 18.0452 1.6542 17.8703 1.6553 17.694V12.0995C1.6542 11.9231 1.68813 11.7483 1.75511 11.5851C1.8221 11.422 1.92081 11.2737 2.04553 11.149C2.17024 11.0243 2.31847 10.9256 2.48163 10.8586C2.64479 10.7916 2.81962 10.7577 2.99599 10.7588H17.6939C17.8702 10.7577 18.0451 10.7916 18.2082 10.8586C18.3714 10.9256 18.5196 11.0243 18.6443 11.149C18.7691 11.2737 18.8678 11.422 18.9348 11.5851C19.0017 11.7483 19.0357 11.9231 19.0346 12.0995ZM5.87598 15.7905C6.37231 15.828 6.86707 15.7 7.28288 15.4264C7.44389 15.2836 7.56955 15.1054 7.64997 14.9057C7.73039 14.7061 7.76337 14.4906 7.74632 14.276C7.76442 14.0609 7.73278 13.8446 7.65385 13.6436C7.57492 13.4427 7.45082 13.2627 7.29115 13.1174C6.87371 12.8257 6.36663 12.6909 5.85943 12.7367H4.37805V17.0567H5.10633V15.7905H5.87598ZM10.0387 17.0567C10.7094 17.1082 11.3739 16.8975 11.8925 16.4691C12.0973 16.2585 12.2566 16.008 12.3605 15.7333C12.4644 15.4585 12.5108 15.1653 12.4966 14.8719C12.5118 14.5811 12.4634 14.2906 12.3548 14.0204C12.2461 13.7503 12.0799 13.5071 11.8677 13.3078C11.3687 12.8983 10.7325 12.6941 10.0884 12.7367H8.60701V17.0567H10.0387ZM14.1601 13.4153H16.3035L16.3118 12.7367H13.4318V17.0898H14.1601V15.3105H16.0718V14.6071H14.1601V13.4153Z"
  //           fill={color ? color : "black"}
  //         />
  //       </g>
  //       <defs>
  //         <clipPath id="clip0_0_1">
  //           <rect width="24" height="24" fill="white" />
  //         </clipPath>
  //       </defs>
  //     </svg>
  //   ),
  //   label: "PDF",
  //   value: context.crtCard.pdf,
  // },
  twitter: {
    svg: (
      <svg
        width="22"
        height="17"
        viewBox="0 0 22 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.46 2C20.69 2.35 19.86 2.58 19 2.69C19.88 2.16 20.56 1.32 20.88 0.31C20.05 0.81 19.13 1.16 18.16 1.36C17.37 0.5 16.26 0 15 0C12.65 0 10.73 1.92 10.73 4.29C10.73 4.63 10.77 4.96 10.84 5.27C7.28004 5.09 4.11004 3.38 2.00004 0.79C1.63004 1.42 1.42004 2.16 1.42004 2.94C1.42004 4.43 2.17004 5.75 3.33004 6.5C2.62004 6.5 1.96004 6.3 1.38004 6C1.38004 6 1.38004 6 1.38004 6.03C1.38004 8.11 2.86004 9.85 4.82004 10.24C4.46004 10.34 4.08004 10.39 3.69004 10.39C3.42004 10.39 3.15004 10.36 2.89004 10.31C3.43004 12 5.00004 13.26 6.89004 13.29C5.43004 14.45 3.58004 15.13 1.56004 15.13C1.22004 15.13 0.880039 15.11 0.540039 15.07C2.44004 16.29 4.70004 17 7.12004 17C15 17 19.33 10.46 19.33 4.79C19.33 4.6 19.33 4.42 19.32 4.23C20.16 3.63 20.88 2.87 21.46 2Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Twitter",
    value: context.crtCard.twitter,
  },
  instagram: {
    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.6739 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.6739 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.6739 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.6739 5 10 5ZM10 7C9.2044 7 8.4413 7.31607 7.87868 7.87868C7.31607 8.4413 7 9.2044 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.4413 12.6839 9.2044 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.2044 12.6839 8.4413 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Instagram",
    value: context.crtCard.instagram,
  },
  linkedin: {
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M18 18H14V11.25C14 10.19 12.81 9.31 11.75 9.31C10.69 9.31 10 10.19 10 11.25V18H6V6H10V8C10.66 6.93 12.36 6.24 13.5 6.24C16 6.24 18 8.28 18 10.75V18ZM4 18H0V6H4V18ZM2 0C2.53043 0 3.03914 0.21071 3.41421 0.58579C3.78929 0.96086 4 1.46957 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.96086 3.78929 0.58579 3.41421C0.21071 3.03914 0 2.53043 0 2C0 1.46957 0.21071 0.96086 0.58579 0.58579C0.96086 0.21071 1.46957 0 2 0Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "LinkedIn",
    value: context.crtCard.linkedin,
  },
  facebook: {
    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 10.0609C20 4.50383 15.5234 0 10 0C4.47656 0 0 4.50383 0 10.0609C0 15.0835 3.65625 19.2454 8.4375 20V12.9691H5.89844V10.0609H8.4375V7.84437C8.4375 5.32325 9.9297 3.93005 12.2148 3.93005C13.3086 3.93005 14.4531 4.12655 14.4531 4.12655V6.60248H13.1914C11.9492 6.60248 11.5625 7.37866 11.5625 8.1745V10.0609H14.3359L13.8926 12.9691H11.5625V20C16.3438 19.2454 20 15.0835 20 10.0609Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Facebook",
    value: context.crtCard.facebook,
  },
  youtube: {
    svg: (
      <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10L13.19 7L8 4V10ZM19.56 2.17C19.69 2.64 19.78 3.27 19.84 4.07C19.91 4.87 19.94 5.56 19.94 6.16L20 7C20 9.19 19.84 10.8 19.56 11.83C19.31 12.73 18.73 13.31 17.83 13.56C17.36 13.69 16.5 13.78 15.18 13.84C13.88 13.91 12.69 13.94 11.59 13.94L10 14C5.81 14 3.2 13.84 2.17 13.56C1.27 13.31 0.69 12.73 0.44 11.83C0.31 11.36 0.22 10.73 0.16 9.93C0.0900001 9.13 0.0599999 8.44 0.0599999 7.84L0 7C0 4.81 0.16 3.2 0.44 2.17C0.69 1.27 1.27 0.69 2.17 0.44C2.64 0.31 3.5 0.22 4.82 0.16C6.12 0.0899998 7.31 0.0599999 8.41 0.0599999L10 0C14.19 0 16.8 0.16 17.83 0.44C18.73 0.69 19.31 1.27 19.56 2.17Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "YouTube",
    value: context.crtCard.youtube,
  },
  whatsapp: {
    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.75 11.96C15 12.09 15.16 12.16 15.21 12.26C15.27 12.37 15.25 12.87 15 13.44C14.8 14 13.76 14.54 13.3 14.56C12.84 14.58 12.83 14.92 10.34 13.83C7.85 12.74 6.35 10.08 6.23 9.91C6.11 9.74 5.27 8.53 5.31 7.3C5.36 6.08 6 5.5 6.26 5.26C6.5 5 6.77 4.97 6.94 5H7.41C7.56 5 7.77 4.94 7.96 5.45L8.65 7.32C8.71 7.45 8.75 7.6 8.66 7.76L8.39 8.17L8 8.59C7.88 8.71 7.74 8.84 7.88 9.09C8 9.35 8.5 10.18 9.2 10.87C10.11 11.75 10.91 12.04 11.15 12.17C11.39 12.31 11.54 12.29 11.69 12.13L12.5 11.19C12.69 10.94 12.85 11 13.08 11.08L14.75 11.96ZM10 0C11.3132 0 12.6136 0.25866 13.8268 0.7612C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.6868 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.03 20 6.2 19.43 4.65 18.45L0 20L1.55 15.35C0.57 13.8 0 11.97 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 11.72 2.54 13.31 3.46 14.61L2.5 17.5L5.39 16.54C6.69 17.46 8.28 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "WhatsApp",
    value: context.crtCard.whatsapp,
  },
  documents: {
    svg: (
      // <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 576 512"
      >
        <path
          d="M147.8 192H480V144C480 117.5 458.5 96 432 96h-160l-64-64h-160C21.49 32 0 53.49 0 80v328.4l90.54-181.1C101.4 205.6 123.4 192 147.8 192zM543.1 224H147.8C135.7 224 124.6 230.8 119.2 241.7L0 480h447.1c12.12 0 23.2-6.852 28.62-17.69l96-192C583.2 249 567.7 224 543.1 224z"
          fill={color ? color : "black"}
        />
      </svg>
    ),
    label: "Documents",
    value: context.crtCard.documents,
  },
  // files: {
  //   svg: (
  //     // <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
  //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
  //       <path
  //         d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256z"
  //         fill={color ? color : "black"}
  //       />
  //     </svg>
  //   ),
  //   label: "Files",
  //   value: context.crtCard.files,
  // },
});
