const OrderCardTemplateDets = [
  {
    header: "Basic",
    cta: "Choose from our 5 business card designs!",
    benefits: [
      "✔ NFC connectivity",
      "✔ Lasting material",
      "✘ Upload your own design (front)",
      "✘ Upload your own design (back)",
      "✘ TROWIT watermark",
    ],
    price: "€10",
    //discountedPrice: "€110",
    fee: "shipping fees (€2.49 anywhere in EU)",
    button: "upgrade",
  },
  {
    header: "Customized",
    cta: "Upload your own design and customize your card!",
    benefits: [
      "✔ NFC connectivity",
      "✔ Lasting material",
      "✔ Upload your own design (front)",
      "✘ Upload your own design (back)",
      "✔ No TROWIT watermark",
      "✔ Upgrade to premium account",
    ],
    benefit3: "✔ No watermark",
    price: "€20",
    fee: "shipping fees (€2.49 anywhere in EU)",
    button: "upgrade",
  },
  {
    header: "Premium",
    cta: "Send us the details, and we'll design your card!",
    benefits: [
      "✔ NFC connectivity",
      "✔ Lasting material",
      "✔ Upload your own design (front)",
      "✔ Upload your own design (back)",
      "✔ No TROWIT watermark",
      "✔ Upgrade to premium account",
    ],
    benefit3: "✔ No watermark",
    price: "€30",
    fee: "shipping fees (€2.49 anywhere in EU)",
    button: "order",
    info: "You will get 3 free reviews of the design, so you can be sure to get exactly what you wished for!",
  },
  {
    header: "Business",
    cta1: "Do you want to order TROWIT Business Cards for the whole team?",
    cta2: "Get in touch for a personalized quote!",
    button: "Contact Sales",
  },
];

export { OrderCardTemplateDets };
