import React, { useState } from "react";
import { Box, Typography, Button, IconButton, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useFormik } from "formik";
import * as Yup from "yup";

import { changePassword } from "../../../firebase";
import useMediaQuery from "src/hooks/useMediaQuery";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";

const PasswordSchema = Yup.object().shape({
  CurrentPassword: Yup.string().required("Required field"),
  Password: Yup.string()
    .min(6, "Minimum 6 characters")
    .max(16, "Maximum 16 characters")
    .required("Required field")
    .matches(
      /^(?=.*[!\"#\$%&\'()\*+\,-\.\/:;\<\=\>?@\[\]\^_\`\{\|\}~])/,
      "Must contain a special character."
    ),
  RepeatPassword: Yup.string()
    .required("Required field")
    .oneOf([Yup.ref("Password")], "Passwords do not match"),
});

const EditPassword = ({ user }) => {
  const [showPasswords, setShowPasswords] = useState(false);
  const mobileView = useMediaQuery(0, "480px");

  const formik = useFormik({
    initialValues: {
      CurrentPassword: "",
      Password: "",
      RepeatPassword: "",
    },
    validationSchema: PasswordSchema,
    onSubmit: (values) => {
      changePassword(user, values.Password, values.CurrentPassword);
    },
  });
  const handleClickShowPassword = () => {
    setShowPasswords(!showPasswords);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        width: "20vw",
        paddingBottom: "2rem",
        ...(mobileView && { minWidth: "85vw" }),
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Edit Password
      </Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={formik.handleSubmit}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mr: 2,
            }}
          >
            <TextField
              name="CurrentPassword"
              label="Current Password"
              type={showPasswords ? "text" : "password"}
              autoComplete="off"
              variant="standard"
              // value={formik.values.CurrentPassword}
              error={
                formik.touched.CurrentPassword &&
                Boolean(formik.errors.CurrentPassword)
              }
              helperText={
                (formik.touched.CurrentPassword &&
                  formik.errors.CurrentPassword) ||
                " "
              }
              onBlur={formik.handleBlur}
              sx={{ mb: 2 }}
              onChange={formik.handleChange}
            />
            <TextField
              name="Password"
              label="New Password"
              type={showPasswords ? "text" : "password"}
              autoComplete="off"
              variant="standard"
              // value={formik.values.Password}
              onChange={formik.handleChange}
              error={formik.touched.Password && Boolean(formik.errors.Password)}
              helperText={
                (formik.touched.Password && formik.errors.Password) || " "
              }
              onBlur={formik.handleBlur}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Repeat Password"
              name="RepeatPassword"
              type={showPasswords ? "text" : "password"}
              autoComplete="off"
              variant="standard"
              // value={formik.values.RepeatPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.RepeatPassword &&
                Boolean(formik.errors.RepeatPassword)
              }
              helperText={
                (formik.touched.RepeatPassword &&
                  formik.errors.RepeatPassword) ||
                " "
              }
              onBlur={formik.handleBlur}
            />
          </Box>
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            sx={{ mt: 2 }}
          >
            {showPasswords ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Box>
        <Button
          variant="outlined"
          type="submit"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditPassword;
