import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import workImg from "../../images/cards_page/card_work@2x.png";
import { Box, CircularProgress } from "@mui/material";
// import cardWork from '../../images/cards_page/card_work@2x.png';
// import cardPersonal from '../../images/cards_page/card_personal@2x.png';
// import createCard from '../../images/cards_page/create_card@2x.png';
//!previous data coming from the fake data
import { collection, getDocs, query, where } from "firebase/firestore";
import { useOutletContext } from "react-router-dom";
import { db } from "src/firebase";
import TCard from "./Card.jsx";
import SearchCard from "./SearchCard";
const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";

const CardsPage = () => {
  const { user, setTitle } = useOutletContext();
  const [cards, setCards] = useState([]);

  const navigate = useNavigate();

  const fetchCards = async () => {
    let cardsArray = [];
    try {
      const cardsQuery = await getDocs(
        query(collection(db, "cards"), where("uid", "==", user?.uid))
      );
      cardsQuery.forEach((doc) => {
        cardsArray.push({ id: doc.id, ...doc.data() });
      });
      setCards(cardsArray);
    } catch (err) {
      console.error(err); // eslint-disable-line
      console.log("An error occured while fetching cards"); // eslint-disable-line
    }
  };

  useEffect(() => {
    setTitle("Cards");
    if (user) fetchCards();
  }, [user]);

  const handleCardSelectClick = (id) => {
    if (id === 3999) {
      navigate("/cards/newcard");
    } else {
      navigate(`/cards/${id}`);
    }
  };

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <SearchCard />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "stretch",
          flexWrap: "wrap",
          // height: "85vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          ["&::-webkit-scrollbar"]: {
            display: "none",
          },
        }}
      >
        <TCard
          key={"new"}
          new={true}
          id={3999}
          // FIXME: we need text for accessibility: type={"Add Card"}
          action={() => handleCardSelectClick(3999)}
        />

        {cards ? (
          cards.map((card) => {
            return (
              <TCard
                key={card.id}
                colour={card.colour}
                id={card.id}
                type={card.type}
                data={card}
                action={() => handleCardSelectClick(card.id)}
                fetchCards={fetchCards}
              />
            );
          })
        ) : (
          <CircularProgress />
        )}
      </Box>
    </>
  );
};

export default CardsPage;
