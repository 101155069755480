import React, { useContext } from "react";
import { MyContext } from "../../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, TextField } from "@mui/material";
import { deleteAccount } from "../../../firebase";
import useMediaQuery from "src/hooks/useMediaQuery";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const DeleteAccount = ({ user, onClose }) => {
  const context = useContext(MyContext);
  const navigate = useNavigate();
  const mobileView = useMediaQuery(0, "480px");

  return (
    <Box
      sx={{
        height: "20vh",
        mx: "2rem",
        my: "1rem",
        ...(mobileView && { minWidth: "85vw" }),
      }}
    >
      <Typography variant="h4">Delete Account </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          paddingTop: "1rem",
        }}
      >
        <Typography variant="paragraph">
          Are you sure you want to delete your account?
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "2rem" }}>
          <Button
            variant="contained"
            color="error"
            sx={{ marginRight: "1rem" }}
            onClick={() => {
              user.delete();
            }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={(event) => {
              onClose(event, "escapeKeyDown");
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteAccount;
