import React, { useContext, useState, useEffect } from "react";
import date from "date-and-time";
// import { useNavigate } from 'react-router-dom';
import { MyContext } from "../../context/MyProvider.js";
import {
  useParams,
  useNavigate,
  useOutletContext,
  Link,
} from "react-router-dom";
import SearchCard from "../Cards_Page/SearchCard";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CircularProgress,
  CardMedia,
  CardContent,
  Grid,
  Chip,
  Dialog,
  Tooltip,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import { db } from "../../firebase";
import {
  getDocs,
  query,
  collection,
  doc,
  setDoc,
  getDoc,
  where,
} from "firebase/firestore";
import { CardInformationData } from "../Cards_Page/CardInformationData";
import avatarPlaceholderImg from "../../images/cards_page/avatar.png";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const ViewInsideGroup = () => {
  const [contactData, setContactData] = useState();
  const [contactGroups, setContactGroups] = useState([]);
  const [selectedContact, setSelectedContact] = useState();
  const [loading, setLoading] = useState(true);
  const [reason, setReason] = useState("");
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { user, setTitle } = useOutletContext();
  const navigate = useNavigate();
  const context = useContext(MyContext);
  const mobileView = useMediaQuery(0, "480px");
  const theme = useTheme();

  const fetchContactGroups = async () => {
    try {
      const docRef = doc(db, `contacts/${user.uid}`);
      const fetchGroups = await getDoc(docRef);
      if (fetchGroups.exists()) {
        setContactGroups(fetchGroups.data().groups);
      }
    } catch (err) {
      console.error(err); // eslint-disable-line
      console.log("An error occured while fetching contacts"); // eslint-disable-line
    }
  };

  const fetchContacts = async () => {
    const fetched = [];
    try {
      const contactQuery = await getDocs(
        query(
          collection(db, `contacts/${user.uid}/All Contacts`),
          where("groups", "array-contains", id)
        )
      );
      contactQuery.forEach((doc) => {
        fetched.push({ id: doc.id, ...doc.data() });
      });

      setContactData(fetched);
      setLoading(false);
    } catch (err) {
      console.error(err); // eslint-disable-line
      console.log("An error occured while fetching contacts"); // eslint-disable-line
      setLoading(false);
    }
  };

  const addToGroup = async (group) => {
    const currentGroups = selectedContact.groups;
    currentGroups.push(group);
    try {
      const docRef = doc(
        db,
        `contacts/${user.uid}/${id}/${selectedContact.id}`
      );
      await setDoc(docRef, { ...selectedContact, groups: currentGroups });
    } catch (err) {
      console.error(err);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (id === "All Contacts") {
      setTitle(`Contacts / All`);
    } else {
      setTitle(`Contacts / ${id}`);
    }

    fetchContactGroups();
    fetchContacts();
  }, []);

  //Todo add the main container so I can input the Contact Card components with the contact details

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        sx={{ justifyContent: "space-between", height: "90vh" }}
      >
        <Grid item xs={10} md={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "3rem",
              marginTop: "1rem",
              ...(mobileView && { marginLeft: 0.5 }),
            }}
          >
            <IconButton
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/contacts");
              }}
            >
              <ArrowBackRoundedIcon sx={{ color: "black", fontSize: 35 }} />
            </IconButton>
            <SearchCard />
          </Box>
        </Grid>
        <Grid item xs={10} md={12} sx={{ justifyContent: "space-between" }}>
          <Grid
            container
            rowSpacing={2}
            sx={{
              justifyContent: "space-between",
              height: "70vh",
              marginTop: "2rem",
            }}
          >
            <Grid item xs={10} md={8}>
              <Box
                sx={{
                  marginLeft: "10%",
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: "1rem",
                  padding: "1rem 2rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  height: "60vh",
                  marginLeft: "3rem",
                  overflowY: "scroll",
                  scrollbarWidth: "none",
                  ["&::-webkit-scrollbar"]: {
                    display: "none",
                  },
                  ...(mobileView && {
                    marginLeft: 0.5,
                    width: "95vw",
                    padding: "0.5rem 1rem",
                  }),
                }}
              >
                {contactData ? (
                  contactData.map((contact, index) => (
                    <Card
                      key={index}
                      variant="no-border"
                      sx={{ minHeight: "90px", width: "95%" }}
                    >
                      <CardActionArea
                        sx={{ minHeight: "90px" }}
                        variant="listitem"
                        onClick={() => {
                          if (mobileView) {
                            navigate(`/contacts/${id}/${contact.id}`);
                          } else {
                            setSelectedContact(contact);
                          }
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid transparent",
                            borderRadius: "8px",
                            margin: "1rem",
                            overflow: "hidden",
                            height: "65px",
                            width: "65px",
                          }}
                        >
                          <img
                            src={contact.profile ?? avatarPlaceholderImg}
                            alt="contact profile image"
                            height="65px"
                            width="65px"
                          />
                        </div>
                        <Box sx={{ margin: "1rem", height: "100%" }}>
                          <Typography variant="h6" sx={{ mb: 2 }}>
                            {`${contact.fields.firstName.value} ${contact.fields.lastName.value}`}
                          </Typography>
                          {contact.fields?.title && (
                            <Typography variant="paragraph">{`${contact.fields?.title.value}`}</Typography>
                          )}
                          {contact.fields?.company && (
                            <Typography variant="paragraph">{` @ ${contact.fields?.company.value}`}</Typography>
                          )}
                        </Box>
                      </CardActionArea>

                      {!mobileView ? (
                        <CardActions>
                          <Link
                            to={`/contacts/${id}/${contact.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Tooltip title="Open contact in new tab" arrow>
                              <LaunchRoundedIcon
                                color="primary"
                                fontSize="large"
                              />
                            </Tooltip>
                          </Link>
                        </CardActions>
                      ) : null}
                    </Card>
                  ))
                ) : loading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant="h5">
                    Sorry you have no contacts
                  </Typography>
                )}
              </Box>
            </Grid>
            {selectedContact && !mobileView ? (
              <Grid
                item
                xs={10}
                md={3}
                sx={{ paddingRight: 1, height: "70vh" }}
              >
                <Card
                  variant="no-border"
                  sx={{
                    flexDirection: "column",
                    height: "100%",
                    overflowY: "scroll",
                    // scrollbarWidth: "none",
                    // ["&::-webkit-scrollbar"]: {
                    //   display: "none",
                    // },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <CardMedia
                      sx={{
                        borderBottom: "solid",
                        borderWidth: "10px",
                        borderColor: selectedContact.cardColor,
                        position: "relative",
                      }}
                      component="img"
                      image={
                        selectedContact?.profile
                          ? selectedContact.profile
                          : avatarPlaceholderImg
                      }
                      alt="Contact profile image"
                      width={350}
                      height={400}
                    />
                    <CardMedia
                      sx={{
                        borderLeft: "solid",
                        borderTop: "solid",
                        borderWidth: "10px",
                        borderColor: selectedContact.cardColor,
                        width: "60px",
                        height: "60px",
                        backgroundColor: "white",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                      component="img"
                      image={
                        selectedContact?.logo
                          ? selectedContact.logo
                          : avatarPlaceholderImg
                      }
                      alt="Contact logo image"
                    />
                  </Box>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "2rem", mb: 2 }}
                    >{`${selectedContact.fields?.firstName?.value} ${selectedContact.fields?.lastName?.value}`}</Typography>
                    {selectedContact.fields?.title && (
                      <Typography variant="paragraph">{`${selectedContact.fields?.title.value}`}</Typography>
                    )}
                    <br />
                    {selectedContact.fields?.company && (
                      <Typography
                        variant="paragraph"
                        sx={{ fontStyle: "italic" }}
                      >{`${selectedContact.fields?.company.value}`}</Typography>
                    )}
                    <Box
                      sx={{ display: "flex", flexDirection: "column", mt: 2 }}
                    >
                      {selectedContact.fieldOrder
                        ?.filter(
                          (field) =>
                            field !== "lastName" &&
                            field !== "firstName" &&
                            field !== "title" &&
                            field !== "company"
                        )
                        .map((field, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "8px",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  minWidth: "35px",
                                  minHeight: "35px",
                                  bgcolor: theme.palette.primary.main,
                                  borderRadius: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {
                                  CardInformationData(context, "white")[field]
                                    .svg
                                }
                              </Box>

                              <Typography
                                variant="paragraph"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  marginLeft: "10px",
                                }}
                              >
                                {selectedContact.fields[field]?.value}
                              </Typography>
                            </Box>
                          );
                        })}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "80%",
                        }}
                        onClick={() => {
                          navigate(`/contacts/${id}/${selectedContact.id}`);
                        }}
                      >
                        <Box sx={{ width: "1em" }}></Box>
                        View
                        <ArrowForwardRoundedIcon />
                      </Button>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 3,
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6">Connection</Typography>
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            mt: 1.5,
                            width: "100%",
                          }}
                        >
                          <HandshakeOutlinedIcon sx={{ fontSize: 40, m: 1 }} />
                          <Typography
                            variant="paragraph"
                            sx={{ fontSize: "1.3rem", mr: 1 }}
                          >
                            {`Added ${date.format(
                              new Date(selectedContact.created),
                              "MMMM DD, YYYY, h:mm A"
                            )}`}
                          </Typography>
                        </Card>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 3,
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6">Tags</Typography>
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            mt: 1.5,
                            width: "100%",
                            padding: 2,
                          }}
                        >
                          <Box>
                            <Typography variant="paragraph">{`Add one or more tags to `}</Typography>
                            <Typography
                              variant="paragraph"
                              sx={{ color: theme.palette.primary.main }}
                            >{`${selectedContact.fields?.firstName?.value} ${selectedContact.fields?.lastName?.value}`}</Typography>
                            <Typography variant="paragraph">{`'s contact.`}</Typography>
                          </Box>
                          <Typography
                            variant="paragraph"
                            sx={{ fontSize: "1.2rem", my: 2 }}
                          >
                            Tags are private—only you can see them.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              wrap: "wrap",
                              width: "100%",
                            }}
                          >
                            {selectedContact.tags
                              ? selectedContact.tags.map((tag, index) => {
                                  return (
                                    <Chip
                                      key={index}
                                      label={tag}
                                      color="primary"
                                      variant="filled"
                                      sx={{ mr: 1.5, mb: 1.5 }}
                                    />
                                  );
                                })
                              : null}
                          </Box>
                        </Card>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: 3,
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6">Groups</Typography>
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            mt: 1.5,
                            width: "100%",
                            padding: 2,
                          }}
                        >
                          <Box>
                            <Typography variant="paragraph">{`See which groups `}</Typography>
                            <Typography
                              variant="paragraph"
                              sx={{ color: theme.palette.primary.main }}
                            >{`${selectedContact.fields?.firstName?.value} ${selectedContact.fields?.lastName?.value}`}</Typography>
                            <Typography variant="paragraph">{` is a part of.`}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              wrap: "wrap",
                              width: "100%",
                              mt: 1,
                            }}
                          >
                            {selectedContact.groups
                              ? selectedContact.groups.map((group, index) => {
                                  return (
                                    <Chip
                                      key={index}
                                      label={group}
                                      color="primary"
                                      variant="filled"
                                      sx={{ mr: 1.5, mb: 1.5 }}
                                    />
                                  );
                                })
                              : null}
                            {contactGroups.length > 1 ? (
                              <Chip
                                label="Add to a Group"
                                color="primary"
                                variant="outlined"
                                sx={{ mb: 1.5 }}
                                onClick={() => {
                                  setReason("group");
                                  setOpen(true);
                                }}
                              />
                            ) : null}
                          </Box>
                        </Card>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <Box
          sx={{
            width: "20rem",
            height: "15rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: 2,
          }}
        >
          <Typography variant="h6">Add to Group</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {contactGroups
              ? contactGroups
                  ?.filter((group) => {
                    return !selectedContact?.groups.includes(group);
                  })
                  .map((group, index) => {
                    return (
                      <Chip
                        key={index}
                        label={group}
                        color="primary"
                        variant="filled"
                        sx={{ mr: 1.5, mb: 1.5 }}
                        onClick={() => {
                          addToGroup(group);
                        }}
                      />
                    );
                  })
              : null}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ViewInsideGroup;
