import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import date from "date-and-time";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { MyContext } from "../../context/MyProvider";
import avatarPlaceholderImg from "../../images/cards_page/avatar.png";
import { CardInformationData } from "../Cards_Page/CardInformationData";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Grid,
  Typography,
  TextField,
  Chip,
  Dialog,
} from "@mui/material";
import { db, storage } from "../../firebase";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// import Wheel from '@uiw/react-color-wheel';

const ViewContact = () => {
  const { user, setTitle } = useOutletContext();
  const theme = useTheme();
  const context = useContext(MyContext);
  const navigate = useNavigate();
  /* DEFINE STATE FOR FILE UPLOAD */
  const [contactData, setContactData] = useState("");
  const [contactGroups, setContactGroups] = useState([]);
  const [contactColor, setContactColor] = useState(theme.palette.primary.main);
  const [fields, setFields] = useState({});
  const [open, setOpen] = useState(false);
  const [tagMessage, setTagMessage] = useState();
  const [tagName, setTagName] = useState("");
  const [reason, setReason] = useState("");
  const [newNote, setNewNote] = useState("");
  const { id, contactid } = useParams();
  const mobileView = useMediaQuery(0, "480px");

  const addToGroup = async (group) => {
    const currentGroups = contactData.groups;
    currentGroups.push(group);
    try {
      const docRef = doc(db, `contacts/${user.uid}/${id}/${contactData.id}`);
      await setDoc(docRef, { ...contactData, groups: currentGroups });
    } catch (err) {
      console.error(err);
    }
    setOpen(false);
  };

  const fetchContact = async () => {
    try {
      const docRef = doc(db, `contacts/${user.uid}/All Contacts/${contactid}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setContactData(docSnap.data());
        setContactColor(docSnap.data().cardColor);
        setFields(docSnap.data().fields);
        setTitle(
          `Contacts / ${id} / ${docSnap.data().fields.firstName.value} ${
            docSnap.data().fields.lastName.value
          }`
        );
      } else {
        console.error("Document does not exist");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchContactGroups = async () => {
    try {
      const docRef = doc(db, `contacts/${user.uid}`);
      const fetchGroups = await getDoc(docRef);
      if (fetchGroups.exists()) {
        setContactGroups(fetchGroups.data().groups);
      }
    } catch (err) {
      console.error(err); // eslint-disable-line
      console.log("An error occured while fetching contacts"); // eslint-disable-line
    }
  };

  const addTag = async (newTag) => {
    const tags = contactData.tags ? contactData.tags : [];
    if (!tags.includes(newTag)) {
      tags.push(newTag);
      try {
        const docRef = doc(
          db,
          `contacts/${user.uid}/All Contacts/${contactid}`
        );
        await setDoc(docRef, { ...contactData, tags: tags });
        setTagMessage("Success!");
      } catch (error) {
        console.error(error);
        setTagMessage("Something went wrong...");
      }
    } else {
      setTagMessage("This tag already exists");
    }
  };

  const addNote = async (newNote) => {
    const notes = contactData.notes ? contactData.notes : [];
    if (!notes.includes(newNote)) {
      notes.push(newNote);
      try {
        const docRef = doc(
          db,
          `contacts/${user.uid}/All Contacts/${contactid}`
        );
        await setDoc(docRef, { ...contactData, notes: notes });
        setNewNote("");
        fetchContact();
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchContact();
    fetchContactGroups();
  }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   addDoc(collection(db, "cards"), {
  //     uid: user.uid,
  //     fields: Object.keys(fields)
  //       .filter((key) => fields[key].value != "")
  //       .reduce((cur, key) => {
  //         return Object.assign(cur, { [key]: fields[key] });
  //       }, {}),
  //     ...imgUrls,
  //     cardColor: cardColor,
  //     cardTitle: cardTitle,
  //     fieldOrder: Object.keys(fields),
  //   });
  //   navigate("/cards");
  // };

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        sx={{
          justifyContent: "space-between",
          height: "88vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          ["&::-webkit-scrollbar"]: {
            display: "none",
          },
          ...(mobileView && { width: "95vw" }),
        }}
      >
        <Grid item xs={12} md={3}>
          <Card
            variant="no-border"
            sx={{
              flexDirection: "column",
              ...(mobileView && { width: "93vw" }),
            }}
          >
            <Box
              sx={{
                position: "relative",
              }}
            >
              <CardMedia
                sx={{
                  borderBottom: "solid",
                  borderWidth: "10px",
                  borderColor: contactColor,
                  position: "relative",
                }}
                component="img"
                image={
                  contactData?.profile
                    ? contactData.profile
                    : avatarPlaceholderImg
                }
                alt="Contact profile image"
                width={350}
                height={400}
              />
              <CardMedia
                sx={{
                  borderLeft: "solid",
                  borderTop: "solid",
                  borderWidth: "10px",
                  borderColor: contactData.cardColor,
                  width: "60px",
                  height: "60px",
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
                component="img"
                image={
                  contactData?.logo ? contactData.logo : avatarPlaceholderImg
                }
                alt="Contact logo image"
              />
            </Box>
            <CardContent>
              <Typography
                variant="h6"
                sx={{ fontSize: "2rem", mb: 2 }}
              >{`${fields?.firstName?.value} ${fields?.lastName?.value}`}</Typography>
              {fields?.title && (
                <Typography variant="paragraph">{`${fields?.title.value}`}</Typography>
              )}
              <br />
              {fields?.company && (
                <Typography
                  variant="paragraph"
                  sx={{ fontStyle: "italic" }}
                >{`${fields?.company.value}`}</Typography>
              )}
              <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                {contactData?.fieldOrder
                  ?.filter(
                    (field) =>
                      field !== "lastName" &&
                      field !== "firstName" &&
                      field !== "title" &&
                      field !== "company"
                  )
                  .map((field, index) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            minWidth: "35px",
                            minHeight: "35px",
                            bgcolor: theme.palette.primary.main,
                            borderRadius: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {CardInformationData(context, "white")[field].svg}
                        </Box>

                        <Typography
                          variant="paragraph"
                          sx={{
                            width: "100%",
                            height: "100%",
                            marginLeft: "10px",
                          }}
                        >
                          {fields[field]?.value}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          {mobileView && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 3,
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{ ...(mobileView && { marginLeft: "1rem" }) }}
              >
                Groups
              </Typography>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  mt: 1.5,
                  width: "100%",
                  padding: 2,
                }}
              >
                <Box>
                  <Typography variant="paragraph">{`See which groups `}</Typography>
                  <Typography
                    variant="paragraph"
                    sx={{ color: theme.palette.primary.main }}
                  >{`${contactData.fields?.firstName?.value} ${contactData.fields?.lastName?.value}`}</Typography>
                  <Typography variant="paragraph">{` is a part of.`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    wrap: "wrap",
                    width: "100%",
                    mt: 1,
                  }}
                >
                  {contactData.groups
                    ? contactData.groups.map((group, index) => {
                        return (
                          <Chip
                            key={index}
                            label={group}
                            color="primary"
                            variant="filled"
                            sx={{ mr: 1.5, mb: 1.5 }}
                          />
                        );
                      })
                    : null}
                  {contactGroups.length > 1 ? (
                    <Chip
                      label="Add to a Group"
                      color="primary"
                      variant="outlined"
                      sx={{ mb: 1.5 }}
                      onClick={() => {
                        setReason("group");
                        setOpen(true);
                      }}
                    />
                  ) : null}
                </Box>
              </Card>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 3,
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{ ...(mobileView && { marginLeft: "1rem" }) }}
            >
              Tags
            </Typography>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 1.5,
                width: "100%",
                padding: 2,
              }}
            >
              <Box>
                <Typography variant="paragraph">{`Add one or more tags to `}</Typography>
                <Typography
                  variant="paragraph"
                  sx={{ color: theme.palette.primary.main }}
                >{`${fields?.firstName?.value} ${fields?.lastName?.value}`}</Typography>
                <Typography variant="paragraph">{`'s contact.`}</Typography>
              </Box>
              <Typography
                variant="paragraph"
                sx={{ fontSize: "1.2rem", my: 2 }}
              >
                Tags are private—only you can see them.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  wrap: "wrap",
                  width: "100%",
                }}
              >
                {contactData.tags
                  ? contactData.tags.map((tag, index) => {
                      return (
                        <Chip
                          key={index}
                          label={tag}
                          color="primary"
                          variant="filled"
                          sx={{ mr: 1.5, mb: 1.5 }}
                        />
                      );
                    })
                  : null}
                <Chip
                  label="Add a tag"
                  color="primary"
                  variant="outlined"
                  sx={{ mb: 1.5 }}
                  onClick={() => {
                    setReason("tag");
                    setOpen(true);
                  }}
                />
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 3,
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              sx={{ ...(mobileView && { marginLeft: "1rem" }) }}
            >
              Notes
            </Typography>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 1.5,
                width: "100%",
                padding: 1,
              }}
            >
              <TextField
                InputProps={{ disableUnderline: true }}
                multiline
                rows={4}
                placeholder="Add a note..."
                onChange={(e) => {
                  setNewNote(e.target.value);
                }}
                variant="standard"
                sx={{ width: "100%" }}
                value={newNote}
              />
              <Button
                sx={{ alignSelf: "flex-end" }}
                variant="contained"
                onClick={() => addNote(newNote)}
              >
                Add Note
              </Button>
            </Card>
            {contactData?.notes
              ? contactData.notes.map((note, index) => {
                  return (
                    <Card key={index} sx={{ my: 1, padding: 1 }}>
                      <Typography variant="paragraph">{note}</Typography>
                    </Card>
                  );
                })
              : null}
          </Box>
        </Grid>
        <Grid item xs={12} md={3} sx={{ pr: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt: 3,
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={{ ...(mobileView && { marginLeft: "1rem" }) }}
            >
              Connection
            </Typography>
            <Card
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 1.5,
                width: "100%",
              }}
            >
              <HandshakeOutlinedIcon sx={{ fontSize: 40, m: 1 }} />
              <Typography
                variant="paragraph"
                sx={{ fontSize: "1.3rem", mr: 1 }}
              >
                {`Added ${date.format(
                  new Date(contactData.created),
                  "MMMM DD, YYYY, h:mm A"
                )}`}
              </Typography>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        onClose={() => {
          setTagMessage("");
          setOpen(false);
        }}
        open={open}
      >
        {reason === "tag" ? (
          <Box
            sx={{
              width: "20rem",
              height: "15rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: 2,
            }}
          >
            <Typography variant="h6">Add tag</Typography>
            <TextField
              label="Tag Name"
              onChange={(e) => {
                setTagName(e.target.value);
              }}
              variant="standard"
              sx={{ width: "100%", my: "1rem" }}
              value={tagName}
            />
            {tagMessage ? <p>{tagMessage}</p> : null}
            <Button variant="contained" onClick={() => addTag(tagName)}>
              Add Tag
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              width: "20rem",
              height: "15rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: 2,
            }}
          >
            <Typography variant="h6">Add to Group</Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {contactGroups
                ? contactGroups
                    ?.filter((group) => {
                      return !contactData?.groups.includes(group);
                    })
                    .map((group, index) => {
                      return (
                        <Chip
                          key={index}
                          label={group}
                          color="primary"
                          variant="filled"
                          sx={{ mr: 1.5, mb: 1.5 }}
                          onClick={() => {
                            addToGroup(group);
                          }}
                        />
                      );
                    })
                : null}
            </Box>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default ViewContact;
