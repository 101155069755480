import React from "react";
import { deleteCard } from "src/firebase";
import { Box, Typography, Button } from "@mui/material";

const DeleteCard = ({ cardData, setShowModal, fetchCards }) => {
  const handleDeleteCard = () => {
    deleteCard(cardData);
    fetchCards();
    setShowModal(false);
  };
  return (
    <Box sx={{ height: "20vh", mx: 4 }}>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Delete Card
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "1rem",
        }}
      >
        <Typography variant="paragraph">
          Are you sure you want to delete this card?
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "2rem" }}>
          <Button
            variant="contained"
            color="error"
            sx={{ marginRight: "1rem" }}
            onClick={() => {
              handleDeleteCard();
            }}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default DeleteCard;
