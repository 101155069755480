import { OrderCardTemplateDets } from "./CardData.js";
import { useState, useEffect } from "react";

const OderCardBusinessTemplate = () => {
  const [details, setDetails] = useState(OrderCardTemplateDets[3]);
  // const details = OrderCardTemplateDets[2];
  // useEffect(() => {
  //   setDetails(OrderCardTemplateDets[3]);
  // }, []);
  return (
    <div className="OC__sub-container3__price-table-and-buttons business">
      <div className="OC__sub-container3__price-frame business">
        <h3 className="OC__sub-container3___header business">
          {details.header}
        </h3>
        <div className="OC__sub-container3__price-frame__ctas business">
          <p className="OC__sub-container3__price-frame__cta business">
            {details.cta1}
          </p>
          <p className="OC__sub-container3__price-frame__cta business">
            {details.cta2}
          </p>
        </div>
      </div>
      {/* price & fees part in the page  */}
      {/* btn part in the page  */}
      <button
        onClick={() => {
          window.location.href =
            "mailto:trowitdigital@gmail.com?subject=Personalised Card&body=Dear%20Trowit%20Sales%2C%20Please%20tell%20me%20more%20about%20your%20personalized%20quotes%20for%20Business%20Cards.";
        }}
        className="OC__sub-container3__button business"
      >
        {details.button}
      </button>
      {/* <a href="mailto=trowitdigital@gmail.com?subject=Personalised Card "> {details[3].button}</a> */}
    </div>
  );
};

export default OderCardBusinessTemplate;
