import { Avatar, Divider, Paper, Typography, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import PersonIcon from "@mui/icons-material/Person";
import ListItemIcon from "@mui/material/ListItemIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import { logout } from "../../firebase";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const AvatarComponent = ({ user }) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState(user?.displayName);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOutClick = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    setUserName(user?.displayName);
  }, [user.displayName]);

  return (
    <div>
      <Box
        sx={{
          border: "solid",
          borderColor: "rgba(100,100,100,0.3)",
          borderRadius: "1rem",
          borderWidth: "1px",
        }}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Button id="basic-button" sx={{ color: "text.secondary" }}>
          <Avatar
            alt={userName}
            src={user.photoURL ? user.photoURL : null}
            sx={{
              bgcolor: theme.palette.primary.main,
              marginRight: "10px",
              borderRadius: "1rem",
              marginLeft: "-1px",
            }}
            variant="rounded"
          />
          <Typography
            variant="paragraph"
            sx={{ fontSize: "1.2rem !important" }}
          >
            {userName}
          </Typography>
          <ExpandMoreIcon fontSize="large" />
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <PersonIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h5"> {userName}</Typography>
            <Typography variant="h6"> {user?.email}</Typography>
          </ListItemText>
        </MenuItem>

        <Divider />
        <MenuItem onClick={() => navigate("/settings")} sx={{ minWidth: 200 }}>
          <ListItemIcon>
            <SettingsIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h5" color="text.secondary">
              Settings
            </Typography>
          </ListItemText>
        </MenuItem>

        {/* <MenuItem>
          <ListItemIcon>
            <LightbulbIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h5" color="text.secondary">
              Tips
            </Typography>
          </ListItemText>
        </MenuItem> */}
        <Divider />

        <MenuItem onClick={() => handleLogOutClick()}>
          <ListItemIcon>
            <LogoutIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h5" color="text.secondary">
              Logout
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AvatarComponent;
