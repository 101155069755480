import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Fab,
  Tooltip,
  Box,
  Typography,
  Link,
  Dialog,
} from "@mui/material";
import avatarPlaceholderImg from "../../images/cards_page/avatar.png";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import VCard from "vcard-creator";
// import cardWork from '../../images/cards_page/card_work@2x.png';
// import cardPersonal from '../../images/cards_page/card_personal@2x.png';
// import createCard from '../../images/cards_page/create_card@2x.png';
import { db, auth } from "src/firebase";
import { addDoc, collection, setDoc, doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { CardInformationData } from "./CardInformationData";
import { MyContext } from "../../context/MyProvider";
import { useContext, useState } from "react";
import OcSelectCard from "./SelectCard";
import { useTheme } from "@mui/material/styles";

const FullCardLayout = (cardData) => {
  
  const [user] = useAuthState(auth);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const context = useContext(MyContext);
  const theme = useTheme();
  // Define a new vCard
  const vcard = new VCard();
  vcard
    // Add personal data
    .addName(cardData.fields.firstName?.value, cardData.fields.lastName?.value)
    // Add work data
    .addCompany(cardData.fields.company?.value)
    .addJobtitle(cardData.fields.title?.value)
    .addRole(cardData.fields.department?.value)
    .addEmail(cardData.fields.email?.value)
    .addPhoneNumber(cardData.fields.phone?.value, "PREF;WORK")
    .addAddress(cardData.fields.address?.value)
    .addPhoto(cardData.fields.profile, "JPEG");
  var data = new Blob([vcard], { type: "text/vcard" });
  var vcardDownload = window.URL.createObjectURL(data);

  const handleAddContact = async () => {
    // addDoc(collection(db, `contacts/${user.uid}/All Contacts`), {
    //   ...cardData,
    //   created: Date(),
    // });
    try {
      await setDoc(
        doc(db, `contacts/${user.uid}/All Contacts/${cardData.id}`),
        {
          ...cardData,
          created: Date.now(),
          groups: ["All Contacts"],
        }
      );
    } catch (err) {
      console.error(err);
    }
    // if (doc(db, `contacts/${user.uid}`).exists(1))
    try {
      const groups = await getDoc(doc(db, `contacts/${user.uid}`));
      if (!groups.data().groups) {
        await setDoc(
          doc(db, `contacts/${user.uid}`),
          { groups: ["All Contacts"] },
          { merge: true }
        );
      } else if (!groups.data().groups.includes("All Contacts")) {
        const currentGroups = groups.data().groups;
        currentGroups.unshift("All Contacts");
        await setDoc(
          doc(db, `contacts/${user.uid}`),
          { groups: currentGroups },
          { merge: true }
        );
      }
    } catch (err) {
      console.error(err);
    }
    navigate("/contacts");
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        {cardData ? (
          <>
            <Card
              variant="greenborder-column"
              sx={{
                borderColor: cardData?.cardColor || theme.palette.primary.main,
                maxWidth: "600px",
                mb: "10vh",
              }}
            >
              <Grid container sx={{ position: "relative" }}>
                {user && user?.uid != cardData.uid ? (
                  <Tooltip
                    sx={{ fontSize: "5rem" }}
                    title="Add Contact on Trowit"
                    arrow
                  >
                    <Fab
                      aria-label={"add contact"}
                      color="primary"
                      sx={{ position: "absolute", left: "85%", top: "5%" }}
                    >
                      <PersonAddIcon
                        fontSize="large"
                        onClick={() => {
                          setOpen(true);
                        }}
                      />
                    </Fab>
                  </Tooltip>
                ) : null}

                {/* PROFILE IMAGE */}
                <CardMedia
                  sx={{
                    borderBottom: "solid",
                    borderWidth: "10px",
                    borderColor: cardData.cardColor,
                  }}
                  component="img"
                  image={
                    cardData?.profile ? cardData.profile : avatarPlaceholderImg
                  }
                  alt="profile image"
                  width={350}
                  height={400}
                />
                {/* LOGO IMAGE */}

                <CardMedia
                  sx={{
                    borderLeft: "solid",
                    borderTop: "solid",
                    borderWidth: "10px",
                    borderColor: cardData.cardColor,
                    width: "60px",
                    height: "60px",
                    backgroundColor: "white",
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                  }}
                  component="img"
                  image={cardData?.logo ? cardData.logo : avatarPlaceholderImg}
                  alt="logo image"
                />
              </Grid>
              <CardContent sx={{ width: "100%" }}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "2rem", mb: 2 }}
                >{`${cardData?.fields?.firstName?.value} ${cardData?.fields?.lastName?.value}`}</Typography>
                {cardData?.fields?.title && (
                  <Typography variant="paragraph">{`${cardData?.fields?.title.value}`}</Typography>
                )}
                <br />
                {cardData?.fields?.company && (
                  <Typography
                    variant="paragraph"
                    sx={{ fontStyle: "italic" }}
                  >{`${cardData?.fields?.company.value}`}</Typography>
                )}
                <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                  {cardData?.fieldOrder
                    ?.filter(
                      (field) =>
                        field !== "lastName" &&
                        field !== "firstName" &&
                        field !== "title" &&
                        field !== "company"
                    )
                    .map((field, index) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              minWidth: "35px",
                              minHeight: "35px",
                              bgcolor: theme.palette.primary.main,
                              borderRadius: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {CardInformationData(context, "white")[field].svg}
                          </Box>

                          <Typography
                            variant="paragraph"
                            sx={{
                              width: "100%",
                              height: "100%",
                              marginLeft: "10px",
                            }}
                          >
                            {cardData?.fields[field]?.value}
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  sx={{ m: "auto" }}
                  variant="outlined"
                  component={Link}
                  startIcon={<PersonAddIcon />}
                  href={vcardDownload}
                  download={`${cardData.fields.firstName?.value}-${cardData.fields.lastName?.value}.vcf`}
                >
                  Download Contact
                </Button>
              </CardActions>
            </Card>
          </>
        ) : null}
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <OcSelectCard handleAddContact={handleAddContact} cardData={cardData} />
      </Dialog>
    </>
  );
};

export default FullCardLayout;
