import React, { useEffect, useState } from "react";
import trowit_logo_img from "../../images/logos/Asset_1.png";
import google_sign_up from "../../images/landing_page/google_sign_up.png";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
  creatHubspotLink,
} from "../../firebase";

import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const SignUpPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    // eslint-disable-next-line
    /* if (!name) alert("Please enter name"); */
    registerWithEmailAndPassword(/* name, */ email, password);
  };

  const ValidationSchema = Yup.object().shape({
    Email: Yup.string().email().required("Required field"),
    Password: Yup.string()
      .min(6, "Minimum 6 characters")
      .max(16, "Maximum 16 characters")
      .matches(
        /^(?=.*[!\"#\$%&\'()\*+\,-\.\/:;\<\=\>?@\[\]\^_\`\{\|\}~])/,
        "Must contain a special character."
      )
      .required("Required field"),
    RepeatPassword: Yup.string()
      .required("Required field")
      .oneOf([Yup.ref("Password")], "Passwords do not match"),
    Terms: Yup.boolean().oneOf([true], "Required field"),
  });

  const createHubspotClient = async (email) => {
    const contactObj = {
      properties: {
        email: `${email}`,
      },
    };
    let responseClone;
    try {
      const response = await fetch("/api/contacts", {
        method: "POST",
        body: JSON.stringify(contactObj),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          responseClone = response.clone();
          return response.json();
        })
        .then(
          (data) => {
            return data;
          },
          (rejectionReason) => {
            console.error(
              "Error parsing JSON from response:",
              rejectionReason,
              responseClone
            );
            responseClone.text().then((bodyText) => {
              console.error(
                "Received the following instead of valid JSON:",
                bodyText
              );
            });
          }
        );
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      Email: "",
      Password: "",
      RepeatPassword: "",
      Terms: false,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      const userID = await registerWithEmailAndPassword(
        values.Email,
        values.Password
      );
      const hsID = await createHubspotClient(values.Email);
      creatHubspotLink(hsID.contact.id, userID,values.Email);
    },
  });

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/cards");
  }, [user, loading]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100vw", display: "flex", justifyContent: "center" }}>
        <img
          src={trowit_logo_img}
          style={{
            height: "8rem",
            marginBlock: "4rem",
          }}
          alt="Trowit logo"
        />
      </Box>

      <Box
        sx={{
          width: "100vw",
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">Create an account to get started</Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            name="Email"
            label="Email"
            type="text"
            variant="outlined"
            value={formik.values.Email}
            onChange={formik.handleChange}
            error={formik.touched.Email && Boolean(formik.errors.Email)}
            helperText={(formik.touched.Email && formik.errors.Email) || " "}
            onBlur={formik.handleBlur}
            sx={{ mt: 4, mb: 1.5, width: "20rem" }}
          />

          <TextField
            name="Password"
            label="Password"
            type="password"
            autoComplete="off"
            variant="outlined"
            onChange={formik.handleChange}
            error={formik.touched.Password && Boolean(formik.errors.Password)}
            helperText={
              (formik.touched.Password && formik.errors.Password) || " "
            }
            onBlur={formik.handleBlur}
            sx={{ mb: 1.5, width: "20rem" }}
          />

          <TextField
            label="Repeat Password"
            name="RepeatPassword"
            type="password"
            autoComplete="off"
            variant="outlined"
            // value={formik.values.RepeatPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.RepeatPassword &&
              Boolean(formik.errors.RepeatPassword)
            }
            helperText={
              (formik.touched.RepeatPassword && formik.errors.RepeatPassword) ||
              " "
            }
            onBlur={formik.handleBlur}
            sx={{ mb: 1.5, width: "20rem" }}
          />
          <FormControlLabel
            control={<Checkbox checked={formik.values.Terms} />}
            label="Agree to the terms and conditions."
            name="Terms"
            onChange={formik.handleChange}
          />

          {/* //! the verify email doesn't get sent to the database. */}

          <Button variant="login" type="submit" sx={{ mb: 1.5, mt: 0.5 }}>
            Submit
          </Button>
        </Box>
        <Link style={{ fontSize: "1.7rem", marginBottom: "2rem" }} to="/log-in">
          Already have an account? Go to Login
        </Link>
        <Button
          onClick={(e) => {
            e.preventDefault();
            signInWithGoogle();
          }}
        >
          <img
            src={google_sign_up}
            style={{ height: "4rem", marginBottom: "2rem" }}
            alt="Google Sign-up button"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default SignUpPage;
