import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import "./SideNavBar.css";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import trowit_logo from "src/images/logos/Asset_8.png";
const drawerWidth = 300;

const openedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",
  alignItems: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiPaper-root": {
    // backgroundColor: "#25dac5",
    backgroundColor: theme.palette.primary.main,

    overflowY: "hidden",
  },
  width: drawerWidth,
  ["@media (max-width:480px)"]: {
    width: "100vw",
  },

  display: "flex",
  alignItems: "left",
  justifyContent: "flex-start",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MenuItemsList = [
  {
    link: "/cards",
    img: "/id-card.png",
    label: "Cards",
  },
  {
    link: "/contacts",
    img: "/contacts.png",
    label: "Contacts",
  },
  /* {
		link: "/backgrounds",
		img: "/backgrounds.png",
		label: "Backgrounds",
	},
	{
		link: "/email-signatures",
		img: "/email.png",
		label: "Email Signatures",
	}, */
  {
    link: "/settings",
    img: "/settings.png",
    label: "Settings",
  },
  {
    link: "/order-card",
    img: "/card-yellow.png",
    label: "Order NFC Card",
  },
];

export default function SideNavBar({ mobile, sidebarOpen, setSidebarOpen }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (mobile && sidebarOpen) {
      setOpen(true);
    }
  }, []);

  return (
    <Box sx={{ width: "6rem" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={mobile && sidebarOpen ? true : open}
        onMouseOver={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <DrawerHeader>
          <a href={"https://app.trowit.com"} className="nav-link">
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "flex-start",
                  marginLeft: "-4px",
                  px: 2.5,
                }}
              >
                <img src={trowit_logo} alt="" className="nav-link-logo" />
                <ListItemText
                  primary={<span className="nav-link-name">TROWIT</span>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </a>
          {mobile ? (
            <CloseRoundedIcon
              sx={{ fontSize: "8vw", color: "white", mr: 1 }}
              onClick={() => {
                setSidebarOpen(false);
              }}
            />
          ) : null}
        </DrawerHeader>
        <Divider />
        {
          <List>
            {MenuItemsList.map((data, index) => (
              <Link
                to={data.link}
                className="nav-link"
                key={data.label}
                onClick={() => {
                  setSidebarOpen(false);
                }}
              >
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "flex-start",
                      marginLeft: "-4px",
                      px: 2.5,
                    }}
                  >
                    <img src={data.img} alt="" className="nav-link-icon" />
                    <ListItemText
                      primary={
                        <span className="nav-link-name">{data.label}</span>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        }
        <Divider />
      </Drawer>
    </Box>
  );
}
