import { useState, useRef } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Button,
  Box,
  CardMedia,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "src/hooks/useMediaQuery";

const ContactCard = ({ contactData, groupName }) => {
  const navigate = useNavigate();
  const handleGroupSelection = (id) => {
    navigate(`/contacts/${id}`);
  };
  const mobileView = useMediaQuery(0, "480px");
  const arrayLength = mobileView ? 4 : 6;
  return (
    <Card
      variant="greenborder-column"
      sx={{
        width: "30rem",
        height: "30rem",
        ...(mobileView && { width: "150px", height: "180px" }),
        justifyContent: "space-around",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <CardActionArea
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        onClick={() => {
          handleGroupSelection(groupName);
        }}
      >
        <CardMedia>
          <Box
            sx={{
              ...(!mobileView && { height: "200px" }),
              ...(mobileView && { height: "100px" }),
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignContent: "space-around",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {contactData
              ?.filter((contact) => {
                return Object.hasOwn(contact, "profile");
              })
              .slice(0, arrayLength)
              .map((contact, index, array) => {
                return (
                  <div
                    key={index}
                    style={{
                      border: "1px solid transparent",
                      borderRadius: "8px",
                      margin: mobileView ? "0.5rem" : "1.5rem",
                      overflow: "hidden",
                      height:
                        array.length > 2 && !mobileView
                          ? "65px"
                          : array.length > 2 && mobileView
                          ? "40px"
                          : array.length <= 2 && mobileView
                          ? "55px"
                          : "80px",
                      width:
                        array.length > 2 && !mobileView
                          ? "65px"
                          : array.length > 2 && mobileView
                          ? "40px"
                          : array.length <= 2 && mobileView
                          ? "55px"
                          : "80px",
                    }}
                  >
                    <img
                      src={contact.profile}
                      alt="contact profile image"
                      height={
                        array.length > 2 && !mobileView
                          ? "65px"
                          : array.length > 2 && mobileView
                          ? "40px"
                          : array.length <= 2 && mobileView
                          ? "55px"
                          : "80px"
                      }
                      width={
                        array.length > 2 && !mobileView
                          ? "65px"
                          : array.length > 2 && mobileView
                          ? "40px"
                          : array.length <= 2 && mobileView
                          ? "55px"
                          : "80px"
                      }
                    />
                  </div>
                );
              })}
          </Box>
        </CardMedia>
        <CardContent
          sx={{
            ...(!mobileView && { height: "1400px" }),
            ...(mobileView && { height: "60px", py: 0 }),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h3"
            pt={mobileView ? 0 : 1}
            sx={{ ...(mobileView && { my: 0 }), ...(!mobileView && { mb: 1 }) }}
          >
            {groupName}
          </Typography>
          <Typography
            variant="paragraph"
            pt={mobileView ? 0 : 1}
            sx={{ mb: 3, ...(mobileView && { mb: 1 }) }}
          >
            {`${contactData ? contactData.length : 0} contacts`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ContactCard;
