import React, { useContext } from "react";
import { MyContext } from "../../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, TextField } from "@mui/material";
import { changeEmail } from "../../../firebase";
import useMediaQuery from "src/hooks/useMediaQuery";

import { useFormik } from "formik";
import * as Yup from "yup";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";

const EmailSchema = Yup.object().shape({
  CurrentPassword: Yup.string().required("Required field"),
  Email: Yup.string().email().required("Required field"),
});

const EditEmail = ({ user }) => {
  const context = useContext(MyContext);
  const navigate = useNavigate();
  const mobileView = useMediaQuery(0, "480px");

  let id = context.userId;

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   fetch(`${backendURL}/api/update/user/${id}`, {
  //     method: "PATCH",
  //     headers: new Headers({
  //       "Content-Type": "application/json",
  //     }),
  //     body: JSON.stringify({
  //       first_name: context.user.first_name,
  //       last_name: context.user.last_name,
  //     }),
  //   }).then((res) => {
  //     if (res.status === 200) {
  //       // console.log("User updated");
  //       context.setUser(context.blankUser);
  //     } else {
  //       context.setUser(context.blankUser);
  //       navigate("/error");
  //     }
  //   });
  // };

  const formik = useFormik({
    initialValues: {
      CurrentPassword: "",
      Email: user.email,
    },
    validationSchema: EmailSchema,
    onSubmit: (values) => {
      changeEmail(user, values.Email, values.CurrentPassword);
    },
  });

  return (
    <Box
      sx={{
        width: "20vw",
        paddingBottom: "2rem",
        ...(mobileView && { minWidth: "85vw" }),
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Edit Email
      </Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          name="CurrentPassword"
          label="Current Password"
          // type={showPasswords ? "text" : "password"}
          type={"password"}
          autoComplete="off"
          variant="standard"
          // value={formik.values.Password}
          onChange={formik.handleChange}
          error={
            formik.touched.CurrentPassword &&
            Boolean(formik.errors.CurrentPassword)
          }
          helperText={
            (formik.touched.CurrentPassword && formik.errors.CurrentPassword) ||
            " "
          }
          onBlur={formik.handleBlur}
          sx={{ mb: 2 }}
        />
        <TextField
          name="Email"
          label="Email"
          type={"text"}
          autoComplete="off"
          variant="standard"
          value={formik.values.Email}
          onChange={formik.handleChange}
          error={formik.touched.Email && Boolean(formik.errors.Email)}
          helperText={(formik.touched.Email && formik.errors.Email) || " "}
          onBlur={formik.handleBlur}
          sx={{ mb: 2 }}
        />

        <Button
          type="submit"
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditEmail;
