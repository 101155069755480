import EditProfile from "./modals/EditProfile";
import UpgradePlan from "./modals/UpgradePlan";
import EditPassword2 from "./modals/EditPassword";
import EditEmail from "./modals/EditEmail";
import DeleteAccount from "./modals/DeleteAccount";
import { useState, useEffect } from "react";
import avatar from "../../images/cards_page/avatar.png";
import credit_card from "../../images/cards_page/credit-card.png";
import icon_logo from "../../images/cards_page/icon_logo.png";
import { Card, Grid, Typography, Box, Button, Dialog } from "@mui/material";
import { useOutletContext } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const CustomDialog = (props) => {
  const { onClose, open, modalType, user, ...other } = props;

  return (
    <Dialog
      open={open}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {modalType === "profile" ? <EditProfile user={user} /> : null}
      {modalType === "upgrade" ? <UpgradePlan user={user} /> : null}
      {modalType === "password" ? <EditPassword2 user={user} /> : null}
      {modalType === "email" ? <EditEmail user={user} /> : null}
      {modalType === "delete" ? (
        <DeleteAccount user={user} onClose={onClose} />
      ) : null}
      {modalType != "delete" && (
        <Button
          onClick={(event) => {
            onClose(event, "escapeKeyDown");
          }}
          variant="contained"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
            margin: "auto",
            marginBottom: "2rem",
          }}
        >
          close
        </Button>
      )}
    </Dialog>
  );
};

const Settings = () => {
  const { user, setTitle } = useOutletContext();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState();

  useEffect(() => {
    setTitle("Settings");
  }, [user]);

  const saveSettings = () => {
    setShowModal(false);
  };

  const SettingsSection = (props) => {
    const indent = props.indent ? "2rem" : "initial";
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: indent,
          }}
        >
          {props.icon}

          <Typography variant="h4" style={{ flexGrow: 1 }}>
            {props.title ?? "Section title"}
          </Typography>
          {props.extra}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "3rem",
            flexGrow: 1,
          }}
        >
          {props.children}
        </Box>
      </Box>
    );
  };

  const SettingsSectionButton = (props) => {
    return (
      <Button variant="setting-link" onClick={props.action}>
        {props.extra}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Typography variant="h5">{props.title}</Typography>
          <Typography sx={{ mt: 0.7 }} variant="paragraph">
            {props.subtitle}
          </Typography>
        </div>
        <ArrowForwardIosRoundedIcon />
      </Button>
    );
  };

  return (
    <>
      <Grid container sx={{ flexDirection: "row" }}>
        <Grid item container lg={6}>
          <Grid item>
            <Card variant="greenborder-row">
              <SettingsSection
                icon={
                  <img
                    src={credit_card}
                    alt="card"
                    style={{ height: " 2rem", width: "2rem" }}
                  />
                }
                title="Subscription"
                // extra={
                //   <Button
                //     variant="upgrade"
                //     sx={{ py: "1rem" }}
                //     onClick={() => {
                //       setModalType("upgrade");
                //       setShowModal(true);
                //     }}
                //   >
                //     + UPGRADE PLAN
                //   </Button>
                // }
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  TROWIT Free
                </Typography>
              </SettingsSection>
            </Card>
          </Grid>
          <Grid item>
            <Card variant="greenborder-row">
              <SettingsSection title="Profile" indent={true}>
                <SettingsSectionButton
                  action={() => {
                    setModalType("profile");
                    setShowModal(true);
                  }}
                  title={user.displayName ?? "First Last Name"}
                  subtitle="Free"
                  extra={
                    <img
                      src={user.photoURL ?? avatar}
                      size="2rem"
                      alt="contact_card"
                      style={{
                        width: "4rem",
                        height: "4rem",
                        marginRight: "1rem",
                      }}
                    />
                  }
                ></SettingsSectionButton>
              </SettingsSection>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={6} sx={{ width: "100%" }}>
          <Card variant="greenborder-row" sx={{ height: "100%" }}>
            <SettingsSection
              icon={
                <img
                  src={icon_logo}
                  size="2rem"
                  alt="contact_card"
                  style={{ height: " 2rem", width: "2rem" }}
                />
              }
              title="Account"
              style={{ flexGrow: "1" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100%",
                }}
              >
                <SettingsSectionButton
                  title="Change your email address"
                  subtitle={user.email}
                  action={() => {
                    setModalType("email");
                    setShowModal(true);
                  }}
                />
                <SettingsSectionButton
                  title="Reset your password"
                  action={() => {
                    setModalType("password");
                    setShowModal(true);
                  }}
                />
                <SettingsSectionButton
                  title="Delete Account"
                  action={() => {
                    setModalType("delete");
                    setShowModal(true);
                  }}
                />
              </Box>
            </SettingsSection>
          </Card>
        </Grid>
      </Grid>
      <CustomDialog
        onClose={saveSettings}
        open={showModal}
        modalType={modalType}
        user={user}
      ></CustomDialog>
    </>
  );
};

export default Settings;
