import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  Dialog,
  Button,
  TextField,
  CardMedia,
  CardContent,
} from "@mui/material";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  setDoc,
} from "firebase/firestore";
import ContactCard from "./ContactCard";
import BigPlus from "../Cards_Page/bigPlus";
import useMediaQuery from "src/hooks/useMediaQuery";

const Contact = () => {
  const { user, setTitle } = useOutletContext();
  const [contacts, setContacts] = useState(null);
  const [contactGroups, setContactGroups] = useState([]);
  const [open, setOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const mobileView = useMediaQuery(0, "480px");

  const fetchContactGroups = async () => {
    try {
      const docRef = doc(db, `contacts/${user.uid}`);
      const fetchGroups = await getDoc(docRef);
      if (fetchGroups.exists()) {
        setContactGroups(fetchGroups.data().groups);
      }
    } catch (err) {
      console.error(err); // eslint-disable-line
      console.log("An error occured while fetching contacts"); // eslint-disable-line
    }
  };

  const fetchContacts = async () => {
    const fetched = [];
    try {
      const contactQuery = await getDocs(
        query(collection(db, `contacts/${user.uid}/All Contacts`))
      );
      contactQuery.forEach((doc) => {
        fetched.push({ id: doc.id, ...doc.data() });
      });
      setContacts(fetched);
    } catch (err) {
      console.error(err); // eslint-disable-line
      console.log("An error occured while fetching contacts"); // eslint-disable-line
    }
  };
  // const fetchContacts2 = async () => {
  //   const fetched = [];
  //   try {
  //     const docRef = doc(db, "contacts", user.uid);
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       console.log(docSnap.data());
  //       setContacts(docSnap.data());
  //     } else {
  //       console.log("Document does not exist");
  //     }
  //   } catch (err) {
  //     console.error(err); // eslint-disable-line
  //     console.log("An error occured while fetching contacts"); // eslint-disable-line
  //   }
  // };

  const CreateContactGroup = async (newGroupName) => {
    const copy = newGroupName.trim();
    
    const groups = contactGroups;
    groups.push(copy);
    
    try {
      setDoc(doc(db, `contacts/${user.uid}`), { groups: groups });
    } catch (err) {
      console.error(err);
    }
    setNewGroupName("");
    setOpen(false);
  };

  useEffect(() => {
    setTitle("Contacts");
    fetchContactGroups();
    fetchContacts();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "flex-start",
          flexWrap: "wrap",
          flexDirection: "row",
          flexWrap: "wrap",
          height: "85vh",
          overflowY: "scroll",
          scrollbarWidth: "none",
          ["&::-webkit-scrollbar"]: {
            display: "none",
          },
        }}
      >
        {contactGroups &&
          contactGroups.map((group, index) => {
            const filteredContacts = contacts?.filter((contact) => {
              return contact.groups.includes(group);
            });
            return (
              <ContactCard
                contactData={filteredContacts}
                groupName={group}
                key={index}
              />
            );
          })}

        <Card
          variant="greenborder-column"
          sx={{
            ...(!mobileView && { width: "30rem", height: "30rem" }),
            ...(mobileView && { width: "150px", height: "180px" }),
            justifyContent: "space-around",
          }}
        >
          <CardActionArea
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <CardMedia
              sx={{
                ...(!mobileView && { height: "200px" }),
                ...(mobileView && { height: "100px" }),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <BigPlus color="primary" />
            </CardMedia>
            <CardContent
              sx={{
                ...(!mobileView && { height: "140px" }),
                ...(mobileView && { height: "70px" }),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h3" pt={1} sx={{ textAlign: "center" }}>
                Add Group
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
      <Dialog
        onClose={() => {
          setNewGroupName("");
          setOpen(false);
        }}
        open={open}
      >
        <Box
          sx={{
            width: "20rem",
            height: "15rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            padding: 2,
          }}
        >
          <Typography variant="h6">Add Group</Typography>
          <TextField
            label="Group Name"
            onChange={(e) => {
              setNewGroupName(e.target.value);
            }}
            variant="standard"
            sx={{ width: "100%", my: "1rem" }}
            value={newGroupName}
          />

          <Button
            variant="contained"
            onClick={() => CreateContactGroup(newGroupName)}
          >
            Add Group
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default Contact;
