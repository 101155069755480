import React, { useEffect, useState } from "react";
import trowit_logo_img from "../../images/logos/Asset_1.png";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, passwordResetEmail } from "../../firebase";
import { Link } from "react-router-dom";

import { Box, Typography, Button, TextField } from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const PasswordReset = () => {
  // eslint-disable-next-line
  const [user, loading, error] = useAuthState(auth);
  const [isSent, setIsSent] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/cards");
  }, [user, loading]);

  const EmailSchema = Yup.object().shape({
    Email: Yup.string().email().required("Required field"),
  });
  const formik = useFormik({
    initialValues: {
      Email: "",
    },
    validationSchema: EmailSchema,
    onSubmit: async (values) => {
      formik.setFieldValue("Email", "");
      formik.setFieldTouched("Email", false);
      const emailSent = await passwordResetEmail(values.Email);
      if (emailSent) {
        setIsSent("Password reset email was sent.");
      } else {
        setIsSent("Email not found.");
      }
    },
  });

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100vw", display: "flex", justifyContent: "center" }}>
        <img
          src={trowit_logo_img}
          style={{
            height: "8rem",
            marginBlock: "4rem",
          }}
          alt="Trowit logo"
        />
      </Box>
      <Box
        sx={{
          width: "100vw",
          flexGrow: "1",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1">Request a password reset email</Typography>

        <Typography variant="paragraph" sx={{ mt: 2 }}>
          {isSent}
        </Typography>

        <Box
          component="form"
          noValidate
          autoComplete="off"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            name="Email"
            label="Email"
            type={"text"}
            autoComplete="off"
            variant="outlined"
            value={formik.values.Email}
            onChange={formik.handleChange}
            error={formik.touched.Email && Boolean(formik.errors.Email)}
            helperText={(formik.touched.Email && formik.errors.Email) || " "}
            onBlur={formik.handleBlur}
            sx={{ mt: 4, width: "20rem" }}
          />

          <Button variant="login" type="submit" sx={{ mb: 1.5, mt: 0.5 }}>
            Submit
          </Button>
        </Box>
        <Link style={{ fontSize: "1.7rem" }} to="/log-in">
          Go to login
        </Link>
      </Box>
    </Box>
  );
};

export default PasswordReset;
