import { useContext, useState, useRef } from "react";
import { MyContext } from "../../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, TextField } from "@mui/material";
import avatar from "../../../images/cards_page/avatar.png";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { updateNameAndPic, db, storage } from "src/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import useMediaQuery from "src/hooks/useMediaQuery";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";

const EditProfile = ({ user }) => {
  const [userName, setUserName] = useState(
    user?.displayName ?? "First Last Name"
  );

  const [imgUrl, setimgUrl] = useState(user.photoURL ? user.photoURL : avatar);
  const [progresspercent, setProgresspercent] = useState(0);
  const profileImgRef = useRef(null);
  const context = useContext(MyContext);
  const navigate = useNavigate();
  const mobileView = useMediaQuery(0, "480px");

  const handleImageUpload = (event, type) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }
    // 👇️ reset file input
    event.target.value = null;

    const storageRef = ref(storage, `${user.uid}/${type}Images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (err) => {
        alert(err); // eslint-disable-line
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setimgUrl(downloadURL);
        });
      }
    );
  };

  const handleProfileUpdate = async (name) => {
    if (imgUrl === avatar) {
     
      await updateNameAndPic(user, name, "");
    } else {
      await updateNameAndPic(user, name, imgUrl);
    }
  };

  return (
    <Box
      sx={{
        // width: "calc(735px - 50vw)",
        minWidth: "20vw",
        maxWidth: "100vw",
        paddingBottom: "2rem",
        ...(mobileView && { minWidth: "85vw" }),
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Edit Profile
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <img
          src={imgUrl}
          alt="contact_card"
          style={{
            width: "12rem",
            height: "12rem",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "2rem" }}>
          <Button
            component="label"
            variant="outlined"
            sx={{ marginRight: "1rem" }}
          >
            Upload
            <input
              hidden
              ref={profileImgRef}
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, "profile")}
            />
          </Button>
          <Button
            variant="outlined"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DeleteForeverRoundedIcon
              sx={{ fontSize: "3rem", fill: "black" }}
              onClick={() => {
                setimgUrl(avatar);
              }}
            />
          </Button>
        </Box>
        <TextField
          label="Name"
          onChange={(e) => {
            setUserName(e.target.value);
          }}
          variant="standard"
          sx={{ width: "70%", marginTop: "1rem" }}
          value={userName}
        />
        <Button
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
          onClick={() => {
            handleProfileUpdate(userName);
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditProfile;
