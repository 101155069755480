import React, { useContext } from "react";
import { MyContext } from "../../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, TextField } from "@mui/material";
const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";

const UpgradePlan = ({ user, saveSettings }) => {
  const context = useContext(MyContext);
  const navigate = useNavigate();

  let id = context.userId;

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(`${backendURL}/api/update/user/${id}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({
        first_name: context.user.first_name,
        last_name: context.user.last_name,
      }),
    }).then((res) => {
      if (res.status === 200) {
        // console.log("User updated");
        context.setUser(context.blankUser);
      } else {
        context.setUser(context.blankUser);
        navigate("/error");
      }
    });
  };

  return (
    <Box sx={{ width: "20vw", paddingBottom: "2rem" }}>
      <Typography variant="h4">Upgrade your Account</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
          onClick={() => {
            saveSettings();
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default UpgradePlan;
