//* imported functionality
import React, { useEffect, useContext, useState, useRef } from "react";
import { MyContext } from "../../context/MyProvider";
import { useParams, useLocation, useNavigate } from "react-router-dom";

//* imported CSS
import "./OrderCrdBasic.css";
import {
  Card,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  CardActionArea,
} from "@mui/material";

//* imported components
import OderCardTemplate from "./OderCardTemplate";
import OcSelectCard from "./OcSelectCard";

//* import images
import mainCard from "../../images/Order_physical_card_page/front2.jpg";
import qrcode from "src/images/Order_physical_card_page/frame.png";

import { useOutletContext } from "react-router-dom";

//* import image data
// import { FrontDesignImages, BackDesignImages } from "./CardDesignData.js";

import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  listAll,
} from "firebase/storage";
import { db, storage } from "../../firebase";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { Parser } from "@json2csv/plainjs";

import { useFormik } from "formik";
import * as Yup from "yup";

import useMediaQuery from "src/hooks/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Chrome from "@uiw/react-color-chrome";

const backendURL =
  process.env.REACT_APP_BACKEND_URL || "https://trowit-backend.herokuapp.com/";
const OrderCardDesignSelect = () => {
  const { user, setTitle } = useOutletContext();
  const mobileView = useMediaQuery(0, "480px");
  const [FrontDesignImages, setFrontDesignImages] = useState([]);
  const [selectedDesigns, setSelectedDesigns] = useState({
    front: mainCard,
    back: "default",
  });
  const [hex, setHex] = useState("#d29c9cff");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const context = useContext(MyContext);
  const DesignImgRef = useRef(null);
  const BackImgRef = useRef(null);
  const colorPickerDiv = useRef(null);
  const colorPickerTrigger = useRef(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const [orderNote, setOrderNote] = useState("");

  // const storage = getStorage();

  let location = useLocation();
  // console.log(location.pathname);

  let { CrdOrdId } = useParams();

  useEffect(() => {
    setTitle(`Order Card ${CrdOrdId}`);
    let loc = location.pathname;
    if (loc.startsWith("/order/card/Basic")) {
      // console.log("basic");
      context.setCrdOrderType("basic");
      setSelectedDesigns({
        front: mainCard,
        back: "default",
      });
    } else if (loc.startsWith("/order/card/Customized")) {
      context.setCrdOrderType("customized");
      // console.log("customized");
    } else if (loc.startsWith("/order/card/Premium")) {
      context.setCrdOrderType("premium");
      // console.log("premium");
    }
  }, [location]);

  const fetchFrontDesigns = async () => {
    let designArray = [];
    const listRef = ref(storage, "CardImages");

    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef, index) => {
          getDownloadURL(itemRef).then((url) => {
            designArray.push({ id: designArray.length, source: url });
            if (index === 0) {
              setSelectedDesigns({ ...selectedDesigns, front: url });
            }
          });
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.error(error);
      });
    setFrontDesignImages(designArray);
  };

  useEffect(() => {
    fetchFrontDesigns();
  }, []);

  const clickColorPicker = (ref, ev) => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (event.target == colorPickerTrigger.current) {
          return;
        } else {
          setShowColorPicker(false);
        }
      }
    };

    if (showColorPicker === false) {
      setShowColorPicker(true);
      document.addEventListener("mousedown", handleClickOutside);
      return;
    }

    if (showColorPicker === true && colorPickerTrigger.current == ev.target) {
      setShowColorPicker(false);
      document.removeEventListener("mousedown", handleClickOutside);
      return;
    }
  };

  const handleUpgradeClick = (type) => {
    if (type === "Basic") {
      if (context.selectedCardForOrder) {
        navigate(`/order/card/Customized/${context.selectedCardForOrder.id}`);
      } else {
        navigate(`/order/card/Customized`);
      }
    } else if (type === "Customized") {
      if (context.selectedCardForOrder) {
        navigate(`/order/card/Premium/${context.selectedCardForOrder.id}`);
      } else {
        navigate(`/order/card/Premium`);
      }
    }
  };

  const handleFrontDesignSelect = (design) => {
    setSelectedDesigns({ ...selectedDesigns, front: design.source });
    context.setCrdOrdFrontDesign(design);
  };

  // const handleBackDesignSelect = (design) => {
  //   console.log(design);
  //   setSelectedDesigns({ ...selectedDesigns, back: design.id });
  //   context.setCrdOrdBackDesign(design);
  // };

  const createHubspotDeal = async () => {
    const docRef = doc(db, "users", user.uid);
    const document = await getDoc(docRef);
    const documentData = document.data();
    const dealObj = {
      "properties": {
        ...(CrdOrdId === "Basic" && { "amount": "10.00" }),
        ...(CrdOrdId === "Premium" && { "amount": "30.00" }),
        ...(CrdOrdId === "Customized" && { "amount": "20.00" }),
        "dealname": `New ${CrdOrdId} order`,
        "pipeline": "default",
        "dealstage": "contractsent",
        "hubspot_owner_id": "428163576",
        "cardfront": `${selectedDesigns.front}`,
        "cardback": `${selectedDesigns.back}`,
        "cardlink": `https://app.trowit.com/card/${context.selectedCardForOrder.id}`,
        "shippingaddress": `${context.orderAddress.first_name} ${context.orderAddress.last_name}, ${context.orderAddress.street_and_number}, ${context.orderAddress.city} ${context.orderAddress.zip_code}, ${context.orderAddress.country}`,
        "phone": `${context.orderAddress.phone_number}`,
        "email": `${user.email}`,
        "note": `${orderNote}`,
      },
      ...(documentData?.hubspot && { "contactID": `${documentData.hubspot}` }),
    };
    try {
      const response = await fetch("/api/deals", {
        method: "POST",
        body: JSON.stringify(dealObj),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        return res.json();
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  /* START STRIPE PAYMENT PROCESS */
  const startPaymentProcess = async () => {
    console.log("Starting payment process");
    try {
      console.log(`${window.location.origin}/payment`);
      const stripeData = {
        cardType: CrdOrdId,
        user_id: user.uid,
        /* SET LIVE APP RETURN URL */
        returnUrl: `${window.location.origin}/payment`,
      };
      await fetch("/api/create-checkout-session", {
        method: "POST",
        body: JSON.stringify(stripeData),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("RES: ", data);
          // HTTP 303 response
          if (data.paymentUrl) {
            console.log("redirecting");
            window.open(data.paymentUrl);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };
  const handleOrderSubmit = async () => {
    const fileData = {
      address: {
        first_name: context.orderAddress.first_name,
        last_name: context.orderAddress.last_name,
        street_and_number: context.orderAddress.street_and_number,
        city: context.orderAddress.city,
        zip_code: context.orderAddress.zip_code,
        country: context.orderAddress.country,
        phone_number: context.orderAddress.phone_number,
      },
      user_id: user.uid,
      order_type: CrdOrdId,
      card: context.selectedCardForOrder,
      url_for_nfc_and_qr: `https://app.trowit.com/card/${context.selectedCardForOrder.id}`,
      card_design_front: selectedDesigns.front,
      card_design_back: selectedDesigns.back,
      note: orderNote,
    };
    try {
      const docRef = await addDoc(collection(db, `orders`), fileData);
      const order = await getDoc(docRef);
      const parser = new Parser({ delimiter: ";" });
      const csv = parser.parse(fileData);

      const file = new Blob([csv], { type: "text/csv" });

      const storageRef = ref(
        storage,
        `orders/${user.uid}/${order.id}/${order.id}.csv`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // console.log(snapshot);
        },
        (err) => {
          alert(err); // eslint-disable-line
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            // console.log(downloadURL);
            setSelectedDesigns({ ...selectedDesigns, front: downloadURL });
          });
        }
      );

      await createHubspotDeal();
      await startPaymentProcess();
      setSelectedDesigns({ front: "", back: "default" });
      context.setSelectedCardForOrder(null);
      context.setOrderAddress({
        first_name: "",
        last_name: "",
        street_and_number: "",
        city: "",
        zip_code: "",
        country: "",
        phone_number: "",
      });
      /* await createHubspotDeal(); */
    } catch (err) {
      console.error(err);
    }
  };

  const handleDesignUpload = async (event, type) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }
    // 👇️ reset file input
    event.target.value = null;

    try {
      const storageRef = ref(
        storage,
        `orders/${user.uid}/orderDesigns/${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // console.log(snapshot);
        },
        (err) => {
          alert(err); // eslint-disable-line
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            let data = { ...selectedDesigns };
            data[type] = downloadURL;
            setSelectedDesigns(data);
          });
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("This field is required."),
    last_name: Yup.string().required("This field is required."),
    street_and_number: Yup.string().required("This field is required."),
    city: Yup.string().required("This field is required."),
    zip_code: Yup.string().required("This field is required."),
    country: Yup.string().required("This field is required."),
    phone_number: Yup.string().required("This field is required."),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      street_and_number: "",
      city: "",
      zip_code: "",
      country: "",
      phone_number: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      handleOrderSubmit();
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        rowSpacing={2}
        sx={{
          justifyContent: "space-between",
          overflowY: "scroll",
          scrollbarWidth: "none",
          ["&::-webkit-scrollbar"]: {
            display: "none",
          },
        }}
      >
        <Grid
          item
          xs={9}
          md={3.5}
          sx={{
            overflowY: "scroll",
            scrollbarWidth: "none",
            ["&::-webkit-scrollbar"]: {
              display: "none",
            },
          }}
        >
          <Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "2rem",
                lineHeight: "32px",
                marginBottom: "1.5rem",
              }}
            >
              Select your card:
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <OcSelectCard />
              {mobileView && (
                <Card
                  onClick={() => {
                    handleUpgradeClick(CrdOrdId);
                  }}
                  sx={{
                    border: "0.3rem solid #898989",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    py: 1,
                    maxHeight: "125px",
                    maxWidth: "140px",
                    minWidth: "100px",
                    ml: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      ...(mobileView && { fontSize: "4.2vw" }),
                    }}
                    pt={1}
                  >{`${CrdOrdId}`}</Typography>

                  {CrdOrdId != "Premium" && (
                    <Box>
                      <Typography sx={{ textAlign: "center", my: 1 }}>
                        Would you like more customization options?
                      </Typography>
                      <Button
                        onClick={() => {
                          handleUpgradeClick(CrdOrdId);
                        }}
                        sx={{
                          textAlign: "center",
                          borderRadius: "4px",
                          borderColor: "transparent",
                          color: "#fff",
                          fontSize: "2.5rem",
                          backgroundColor: "#898989",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          maxWidth: "70%",
                          minWidth: "70%",
                          margin: "auto",
                          mb: 0.5,
                        }}
                      >
                        UPGRADE
                      </Button>
                    </Box>
                  )}
                </Card>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "95%",
              overflow: "hidden",
              alignSelf: "center",
              // overflowX: "hidden",
              // scrollbarWidth: "none",
              // ["&::-webkit-scrollbar"]: {
              //   display: "none",
              // },
            }}
          >
            {!mobileView && <OderCardTemplate />}
          </Box>
        </Grid>
        {!mobileView && <hr style={{ opacity: "0.3" }} />}
        <Grid item xs={9} md={4}>
          <Box sx={{ mb: 2 }}>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "2rem",
                lineHeight: "32px",
                marginBottom: "1.5rem",
              }}
            >
              Preview of Card
            </Typography>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "320px",
                overflow: "hidden",
              }}
            >
              <img
                style={{ position: "absolute", objectFit: "cover" }}
                width="100%"
                height="320px"
                src={selectedDesigns.front}
                alt="preview of physical card "
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 15,
                  left: 12,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="paragraph"
                  sx={{ color: "white", fontSize: 20 }}
                >
                  FIRST LAST NAME
                </Typography>
                <Typography
                  variant="paragraph"
                  sx={{ color: "white", fontSize: 15 }}
                >
                  TITLE
                </Typography>
                <Typography
                  variant="paragraph"
                  sx={{ color: "white", fontSize: 15 }}
                >
                  COMPANY
                </Typography>
              </Box>

              <img
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  width: "100px",
                }}
                src={qrcode}
              />
              {CrdOrdId === "Basic" && (
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    px: 1,
                  }}
                >
                  <Typography
                    variant="paragraph"
                    sx={{
                      color: "white",
                      fontSize: 8,
                    }}
                  >
                    CREATED WITH TROWIT.COM
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "2rem",
                lineHeight: "32px",
                marginBottom: "1rem",
              }}
            >
              Choose your front design
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
                maxWidth: "90vw",
                py: 1,
                overflowY: "scroll",
                scrollbarWidth: "none",
                ["&::-webkit-scrollbar"]: {
                  display: "none",
                },
              }}
            >
              {CrdOrdId != "Basic" && (
                <Card
                  variant="greenborder-column"
                  sx={{
                    minWidth: "65px",
                    minHeight: "38px",
                    height: "38px",
                    margin: 0,
                    boxShadow: 0,
                    borderWidth: "0.3rem",
                  }}
                >
                  <CardActionArea
                    sx={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => DesignImgRef.current.click()}
                  >
                    Upload
                    <input
                      hidden
                      ref={DesignImgRef}
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleDesignUpload(e, "front")}
                    />
                  </CardActionArea>
                </Card>
              )}

              {FrontDesignImages.map((e, index) => (
                <Box
                  onClick={() => handleFrontDesignSelect(e)}
                  key={index}
                  sx={{
                    marginLeft: "5px",
                    width: "65px",
                    height: "38px",
                    ...(e.id === context.crdOrdFontDesign?.id && {
                      boxShadow: "0px 0px 2px 4px rgba(22, 196, 138, 0.5)",
                    }),
                  }}
                >
                  <img
                    src={e.source}
                    alt={`front design ${index}`}
                    style={{
                      width: "65px",
                      height: "38px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
          <hr style={{ opacity: "0.3" }} />
          {CrdOrdId != "Basic" && (
            <Box sx={{ my: 2, display: "flex", flexDirection: "column" }}>
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "2rem",
                  lineHeight: "32px",
                  marginBottom: "1rem",
                }}
              >
                Choose your back design
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <Box
                  ref={colorPickerTrigger}
                  onClick={(event) => {
                    clickColorPicker(colorPickerDiv, event);
                  }}
                  sx={{
                    backgroundImage:
                      "conic-gradient(red, yellow, lime, aqua, blue, magenta, red)",
                    minWidth: "65px",
                    minHeight: "38px",
                  }}
                ></Box>
                {/* {BackDesignImages.map((e) => (
                <Box
                  key={e.id}
                  onClick={() => handleBackDesignSelect(e)}
                  sx={{
                    backgroundColor: e.srcName,
                    minWidth: "65px",
                    minHeight: "38px",
                    ...(e.id === context.crdOrdBackDesign?.id && {
                      boxShadow: "0px 0px 2px 4px rgba(22, 196, 138, 0.5)",
                    }),
                  }}
                ></Box>
              ))} */}
                {CrdOrdId === "Premium" && (
                  <Card
                    variant="greenborder-column"
                    sx={{
                      minWidth: "65px",
                      minHeight: "38px",
                      height: "38px",
                      margin: 0,
                      boxShadow: 0,
                      borderWidth: "0.3rem",
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      onClick={() => BackImgRef.current.click()}
                    >
                      Upload
                      <input
                        hidden
                        ref={BackImgRef}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleDesignUpload(e, "back")}
                      />
                    </CardActionArea>
                  </Card>
                )}
              </Box>
              {showColorPicker && (
                <Box sx={{ py: 1, width: "230px" }} ref={colorPickerDiv}>
                  <Chrome
                    inputType="hexa"
                    color={hex}
                    style={{ float: "left" }}
                    onChange={(color) => {
                      setHex(color.hexa);
                      setSelectedDesigns({
                        ...selectedDesigns,
                        back: color.hexa,
                      });
                    }}
                  />
                </Box>
              )}
            </Box>
          )}

          {CrdOrdId === "Basic" && (
            <Typography
              style={{
                fontFamily: "Raleway",
                color: "#333333",
                fontWeight: "300",
                fontSize: "1.5rem",
                lineHeight: "2rem",
                letterSpacing: "0.25px",
                marginBlockStart: "1rem",
              }}
            >
              Do you want to customize the design, add a picture on the back or
              rearrange the fields on the card?
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontWeight: "700",
                  fontSize: "1.5rem",
                }}
              >
                +UPGRADE
              </span>
            </Typography>
          )}
          {CrdOrdId === "Premium" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 3,
                mb: 1,
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{ ...(mobileView && { marginLeft: "1rem" }) }}
              >
                Add a note
              </Typography>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 1.5,
                  width: "100%",
                  padding: 1,
                }}
              >
                <TextField
                  InputProps={{ disableUnderline: true }}
                  multiline
                  rows={4}
                  placeholder="Add a note..."
                  onChange={(e) => {
                    setOrderNote(e.target.value);
                  }}
                  variant="standard"
                  sx={{ width: "100%" }}
                  value={orderNote}
                />
              </Card>
            </Box>
          )}
        </Grid>
        {!mobileView && <hr style={{ opacity: "0.3" }} />}
        <Grid item xs={10} md={3.5} sx={{ width: "100%" }}>
          <Box
            component="form"
            // noValidate
            autoComplete="on"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
            onSubmit={formik.handleSubmit}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "2rem",
                  lineHeight: "32px",
                }}
              >
                Shipping Address
              </Typography>
            </Box>
            <TextField
              name="first_name"
              label="First Name"
              type="text"
              variant="standard"
              value={formik.values.first_name}
              onChange={(event) => {
                formik.handleChange(event);
                context.handleOrderAddress(event);
              }}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={
                (formik.touched.first_name && formik.errors.first_name) || " "
              }
              onBlur={formik.handleBlur}
              sx={{ mt: 4, mb: 1.5, width: "20rem" }}
            />
            <TextField
              name="last_name"
              label="Last Name"
              type="text"
              variant="standard"
              onChange={(event) => {
                formik.handleChange(event);
                context.handleOrderAddress(event);
              }}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={
                (formik.touched.last_name && formik.errors.last_name) || " "
              }
              onBlur={formik.handleBlur}
              sx={{ mb: 1.5, width: "20rem" }}
            />
            <TextField
              name="street_and_number"
              label="Street & Number"
              type="text"
              variant="standard"
              onChange={(event) => {
                formik.handleChange(event);
                context.handleOrderAddress(event);
              }}
              error={
                formik.touched.street_and_number &&
                Boolean(formik.errors.street_and_number)
              }
              helperText={
                (formik.touched.street_and_number &&
                  formik.errors.street_and_number) ||
                " "
              }
              onBlur={formik.handleBlur}
              sx={{ mb: 1.5, width: "20rem" }}
            />
            <TextField
              name="city"
              label="City"
              type="text"
              variant="standard"
              onChange={(event) => {
                formik.handleChange(event);
                context.handleOrderAddress(event);
              }}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={(formik.touched.city && formik.errors.city) || " "}
              onBlur={formik.handleBlur}
              sx={{ mb: 1.5, width: "20rem" }}
            />
            <TextField
              name="zip_code"
              label="Zip Code"
              type="text"
              variant="standard"
              onChange={(event) => {
                formik.handleChange(event);
                context.handleOrderAddress(event);
              }}
              error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
              helperText={
                (formik.touched.zip_code && formik.errors.zip_code) || " "
              }
              onBlur={formik.handleBlur}
              sx={{ mb: 1.5, width: "20rem" }}
            />
            <TextField
              name="country"
              label="Country"
              type="text"
              variant="standard"
              onChange={(event) => {
                formik.handleChange(event);
                context.handleOrderAddress(event);
              }}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={
                (formik.touched.country && formik.errors.country) || " "
              }
              onBlur={formik.handleBlur}
              sx={{ mb: 1.5, width: "20rem" }}
            />
            <TextField
              name="phone_number"
              label="Phone Number"
              type="text"
              variant="standard"
              onChange={(event) => {
                formik.handleChange(event);
                context.handleOrderAddress(event);
              }}
              error={
                formik.touched.phone_number &&
                Boolean(formik.errors.phone_number)
              }
              helperText={
                (formik.touched.phone_number && formik.errors.phone_number) ||
                " "
              }
              onBlur={formik.handleBlur}
              sx={{ mb: 1.5, width: "20rem" }}
            />
            {/* <input
              value={context.orderAddress.first_name}
              name="first_name"
              onChange={context.handleOrderAddress}
              placeholder="Jane"
            /> */}
            <Button
              disabled={context.selectedCardForOrder == undefined}
              variant="login"
              type="submit"
              sx={{ mb: 1.5, mt: 0.5 }}
            >
              Order Trowit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderCardDesignSelect;
