import { doc, getDoc, setDoc } from "firebase/firestore";
import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { useOutletContext, useParams, useNavigate } from "react-router-dom";
import { MyContext } from "../../context/MyProvider";
import avatarPlaceholderImg from "../../images/cards_page/avatar.png";
import addProfile from "../../images/cards_page/add picture.png";
import addLogo from "../../images/cards_page/add logo.png";
import { CardInformationData } from "./CardInformationData";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import {
  CardActionArea,
  Typography,
  Button,
  TextField,
  Grid,
  CardMedia,
  CardContent,
  Card,
  Box,
  IconButton,
} from "@mui/material";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { db, storage } from "../../firebase";
import "./InformationButton.css";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Chrome from "@uiw/react-color-chrome";
import Cropper from "react-easy-crop";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import "./react-easy-crop.css";
import { useFormik } from "formik";
import * as Yup from "yup";

const EditCard = () => {
  const { user, setTitle } = useOutletContext();
  const mobileView = useMediaQuery(0, "480px");
  const theme = useTheme();

  /* DEFINE STATE FOR FILE UPLOAD */
  const [imgUrls, setimgUrls] = useState({});
  const [progresspercent, setProgresspercent] = useState(0);
  const [cardColor, setCardColor] = useState(theme.palette.primary.main);
  const [cardTitle, setCardTitle] = useState({
    label: "Card Title",
    value: "",
  });
  const [ordered, setOrdered] = useState([]);
  const [hex, setHex] = useState("#d29c9cff");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const context = useContext(MyContext);
  const [fields, setFields] = useState({
    firstName: { label: "First Name", value: "" },
    lastName: { label: "Last Name", value: "" },
    title: { label: "Title", value: "" },
    department: { label: "Department", value: "" },
    company: { label: "Company", value: "" },
  });
  const profileImgRef = useRef(null);
  const logoImgRef = useRef(null);
  const colorPickerDiv = useRef(null);
  const colorPickerTrigger = useRef(null);
  const navigate = useNavigate();
  let { id } = useParams();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
    // console.log(croppedArea, croppedAreaPixels);
  }, []);
  const [cropping, setCropping] = useState(false);
  const [profile, setProfile] = useState();
  const [logo, setLogo] = useState();

  useEffect(() => {
    setTitle("Cards / Edit Card");
    const fetchCard = async () => {
      try {
        const docRef = doc(db, "cards", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setCardColor(docSnap.data().cardColor);
          setimgUrls({
            ...(Object.hasOwn(docSnap.data(), "profile") && {
              profile: docSnap.data().profile,
            }),
            ...(Object.hasOwn(docSnap.data(), "logo") && {
              logo: docSnap.data().logo,
            }),
          });
          setProfile(docSnap.data().profile);
          setLogo(docSnap.data().logo);
          setCardTitle(docSnap.data().cardTitle);

          const orderedFields = {};
          docSnap.data().fieldOrder.map((field) => {
            orderedFields[field] = docSnap.data().fields[field];
            formik.values[field] = docSnap.data().fields[field].value;
          });
          setFields(orderedFields);
          setOrdered(Object.keys(orderedFields));
        } else {
          console.error("Document does not exist");
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCard();
  }, [id]);

  const onDragEnd = (results) => {
    const { destination, source, draggableId } = results;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const reorder = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const reordered = reorder(ordered, source.index, destination.index);
    setOrdered(reordered);
  };

  const clickColorPicker = (ref, ev) => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (event.target == colorPickerTrigger.current) {
          return;
        } else {
          setShowColorPicker(false);
        }
      }
    };

    if (showColorPicker === false) {
      setShowColorPicker(true);
      document.addEventListener("mousedown", handleClickOutside);
      return;
    }

    if (showColorPicker === true && colorPickerTrigger.current == ev.target) {
      setShowColorPicker(false);
      document.removeEventListener("mousedown", handleClickOutside);
      return;
    }
  };

  const handleUploads = async (fileToUpload, type) => {
    const file = fileToUpload;
    if (!file) {
      return;
    }

    const storageRef = ref(storage, `${user.uid}/${type}Images/${file.name}`);

    try {
      const snapshot = await uploadString(storageRef, file, "data_url").then(
        (snapshot) => {
          return snapshot;
        }
      );
      const dwnld = await getDownloadURL(snapshot.ref).then((downloadURL) => {
        let data = { ...imgUrls };
        // console.log(downloadURL);
        data[type] = downloadURL;
        setimgUrls(data);
        return downloadURL;
      });
      return dwnld;
    } catch (error) {
      console.error(error);
    }
  };

  // const handleImageUpload = (event, type) => {
  //   const file = event.target.files && event.target.files[0];
  //   if (!file) {
  //     return;
  //   }
  //   // 👇️ reset file input
  //   event.target.value = null;

  //   const storageRef = ref(storage, `${user.uid}/${type}Images/${file.name}`);
  //   const uploadTask = uploadBytesResumable(storageRef, file);

  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const progress = Math.round(
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       );
  //       setProgresspercent(progress);
  //     },
  //     (err) => {
  //       alert(err); // eslint-disable-line
  //     },
  //     () => {
  //       getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
  //         let data = { ...imgUrls };
  //         data[type] = downloadURL;
  //         setimgUrls(data);
  //       });
  //     }
  //   );
  // };

  const handleImageUpload = (event, type) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }
    // 👇️ reset file input
    event.target.value = null;

    const newImg = URL.createObjectURL(file);
    // console.log(newImg);
    let data = { ...imgUrls };
    data[type] = newImg;
    setimgUrls(data);

    if (type === "profile") {
      const reader = new FileReader();
      reader.onload = (evt) => {
        // console.log(evt.target.result);
        setProfile(evt.target.result);
      };
      reader.readAsDataURL(file);
      // console.log(profile);
      setCropping(true);
    } else {
      const reader = new FileReader();
      reader.onload = (evt) => {
        // console.log(evt.target.result);
        setLogo(evt.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDocUpload = (event) => {
    const file = event.target.files && event.target.files[0];
    if (!file) {
      return;
    }
    // 👇️ reset file input
    event.target.value = null;

    const storageRef = ref(storage, `${user.uid}/Documents/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgresspercent(progress);
      },
      (err) => {
        alert(err); // eslint-disable-line
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let data = { ...fields };
          data["documents"].url = downloadURL;
          data["documents"].value = file.name;
          formik.values["documents"] = file.name;
          setFields(data);
        });
      }
    );
  };

  const additionalInformation = CardInformationData(context);

  const handleItemClick = (key, label) => {
    if (!Object.keys(fields).includes(key)) {
      let data = { ...fields };
      data[key] = { label: label, value: "" };
      formik.values[key] = "";
      formik.setTouched({}, false);
      setFields(data);
      const oldOrder = [...ordered];
      if (key === "phone") {
        if (oldOrder.includes("email")) {
          oldOrder.splice(5, 0, key);
        } else {
          oldOrder.splice(4, 0, key);
        }
      } else if (key === "email") {
        oldOrder.splice(4, 0, key);
      } else {
        oldOrder.push(key);
      }
      setOrdered(oldOrder);
      return;
    } else {
      let data = { ...fields };
      delete data[key];
      setFields(data);
      const newOrder = [...ordered].filter((word) => {
        return word !== key;
      });
      setOrdered(newOrder);
    }
  };
  const handleFieldChange = (value, event) => {
    let data = { ...fields };
    data[value].value = event.target.value;
    setFields(data);
  };

  const handleUpdate = async (prof, log) => {
    try {
      const cardRef = doc(db, "cards", id);
      await setDoc(cardRef, {
        uid: user.uid,
        fields: Object.keys(fields)
          // .filter((key) => fields[key].value != "")
          .reduce((cur, key) => {
            return Object.assign(cur, { [key]: fields[key] });
          }, {}),
        ...imgUrls,
        logo: log,
        profile: prof,
        cardColor: cardColor,
        cardTitle: cardTitle,
        fieldOrder: ordered,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const createImage = async (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  /**
   * Returns the new bounding area of a rotated rectangle.
   */
  const rotateSize = (width, height, rotation) => {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  };

  const getCroppedImg = async (
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    ctx.drawImage(image, 0, 0);
    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
          // console.log(evt.target.result);
          setProfile(evt.target.result);
        };
        reader.readAsDataURL(file);

        resolve(URL.createObjectURL(file));
      }, "image/jpeg");
    });
  };

  const showCroppedImage = async (imageSrc, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      // console.log("done", { croppedImage });
      setimgUrls({ ...imgUrls, profile: croppedImage });
    } catch (e) {
      console.error(e);
    }
  };

  const colour = [
    {
      id: 1,
      colour: theme.palette.primary.main,
    },
    {
      id: 2,
      colour: "#3A59AE",
    },
    {
      id: 3,
      colour: "#628AF8",
    },
    {
      id: 4,
      colour: "#8F5FDE",
    },
    {
      id: 5,
      colour: "#3BB55D",
    },
    {
      id: 6,
      colour: "#FDC631",
    },
    {
      id: 7,
      colour: "#EA3A2E",
    },
    {
      id: 8,
      colour: "#EE85DD",
    },
  ];

  const ValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required."),
    lastName: Yup.string().required("This field is required."),
    title: Yup.string(),
    company: Yup.string(),
    department: Yup.string(),
    phone: Yup.string(),
    email: Yup.string(),
    address: Yup.string(),
    website: Yup.string().url("Must start with 'http'"),
    link: Yup.string().url("Must start with 'http'"),
    twitter: Yup.string().url("Must start with 'http'"),
    instagram: Yup.string().url("Must start with 'http'"),
    linkedin: Yup.string().url("Must start with 'http'"),
    facebook: Yup.string().url("Must start with 'http'"),
    youtube: Yup.string().url("Must start with 'http'"),
    whatsapp: Yup.string().matches(
      /^\+[1-9]{1}[0-9]{3,14}$/,
      "Number must start with + country code and contain no spaces "
    ),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      title: "",
      company: "",
      department: "",
      phone: "",
      email: "",
      address: "",
      website: "",
      link: "",
      twitter: "",
      instagram: "",
      linkedin: "",
      facebook: "",
      youtube: "",
      whatsapp: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      let prof = "";
      let log = "";
      if (imgUrls["profile"] && !imgUrls["profile"].startsWith("http")) {
        // console.log(profile);
        prof = await handleUploads(profile, "profile");
      } else {
        prof = profile;
      }
      if (imgUrls["logo"] && !imgUrls["logo"].startsWith("http")) {
        // console.log(logo);
        log = await handleUploads(logo, "logo");
      } else {
        log = logo;
      }
      await handleUpdate(prof, log);
      navigate("/cards");
    },
  });

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box sx={{ ml: 1, mb: 4 }}>
          <IconButton
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackRoundedIcon sx={{ color: "black", fontSize: 35 }} />
          </IconButton>
          <TextField
            sx={{ width: 350, borderRadius: "50%" }}
            InputProps={{ style: { fontSize: "1.5rem" } }}
            InputLabelProps={{ style: { fontSize: "1.5rem" } }}
            key={"cardTitle"}
            label={cardTitle.label}
            value={cardTitle.value}
            onChange={(event) =>
              setCardTitle({ ...cardTitle, value: event.target.value })
            }
            variant="outlined"
          />
        </Box>
        <Grid
          container
          rowSpacing={2}
          id={"CreateNewCard"}
          sx={{
            justifyContent: "center",
            overflowY: "scroll",
            scrollbarWidth: "none",
            ["&::-webkit-scrollbar"]: {
              display: "none",
            },
          }}
        >
          <Grid
            item
            xs={10}
            md={6}
            sx={{
              maxWidth: "100%",
              ...(!mobileView && {
                height: "85vh",
                overflowY: "scroll",
                scrollbarWidth: "none",
                ["&::-webkit-scrollbar"]: {
                  display: "none",
                },
              }),
            }}
          >
            <Box xs={12}>
              <Card variant="outlined" sx={{ width: 350 }}>
                <Grid
                  container
                  sx={{
                    position: "relative",
                    backgroundColor: cardColor || theme.palette.primary.main,
                    minWidth: 350,
                    minHeight: 230,
                    overflow: "visible",
                    marginBottom: "50%",
                  }}
                >
                  {/* LOGO IMAGE */}
                  <Box
                    onClick={() => logoImgRef.current.click()}
                    sx={{
                      maxHeight: "80px",
                    }}
                  >
                    <input
                      hidden
                      ref={logoImgRef}
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageUpload(e, "logo")}
                    />
                    <CardMedia
                      sx={{
                        maxHeight: "45px",
                        minHeight: "45px",
                        objectFit: "contain",
                        position: "absolute",
                        top: "8%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      component="img"
                      image={imgUrls?.logo ? imgUrls.logo : addLogo}
                      alt="logo image"
                    />
                  </Box>
                  {/* PROFILE IMAGE */}
                  {!cropping && (
                    <>
                      <Box onClick={() => profileImgRef.current.click()}>
                        {!imgUrls["profile"] && (
                          <input
                            hidden
                            ref={profileImgRef}
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, "profile")}
                          />
                        )}
                        <CardMedia
                          sx={{
                            borderRadius: "50%",
                            maxWidth: 275,
                            maxHeight: 275,
                            minWidth: 275,
                            minHeight: 275,
                            position: "absolute",
                            top: "40%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            left: 0,
                            right: 0,
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          component="img"
                          image={
                            imgUrls?.profile ? imgUrls.profile : addProfile
                          }
                          alt="profile image"
                          // width={350}
                          // height={400}
                        />
                      </Box>
                      {imgUrls["profile"] && (
                        <Button
                          variant="outlined"
                          onClick={() => profileImgRef.current.click()}
                          sx={{
                            position: "absolute",
                            bottom: "-70%",
                            right: 25,
                          }}
                        >
                          <input
                            hidden
                            ref={profileImgRef}
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, "profile")}
                          />
                          <EditRoundedIcon />
                        </Button>
                      )}
                    </>
                  )}
                  {imgUrls["profile"] && cropping ? (
                    <Box
                      sx={{
                        borderRadius: "50%",
                        position: "absolute",
                        top: "40%",
                        marginLeft: "auto",
                        marginRight: "auto",

                        left: 0,
                        right: 0,
                        textAlign: "center",
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          maxWidth: 275,
                          maxHeight: 275,
                          minWidth: 275,
                          minHeight: 275,
                          overflow: "hidden",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Cropper
                          image={
                            imgUrls?.profile
                              ? imgUrls.profile
                              : avatarPlaceholderImg
                          }
                          crop={crop}
                          zoom={zoom}
                          aspect={4 / 4}
                          onCropChange={setCrop}
                          onCropComplete={onCropComplete}
                          onZoomChange={setZoom}
                          cropShape={"round"}
                          minZoom="0.1"
                          maxZoom="6"
                          cropSize={{ width: 275, height: 275 }}
                          disableAutomaticStylesInjection={true}
                          objectFit="cover"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          mt: 1,
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => profileImgRef.current.click()}
                        >
                          <input
                            hidden
                            ref={profileImgRef}
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(e, "profile")}
                          />
                          <EditRoundedIcon />
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            showCroppedImage(
                              imgUrls.profile,
                              croppedAreaPixels
                            );
                            setCropping(false);
                          }}
                        >
                          Crop
                        </Button>
                      </Box>
                    </Box>
                  ) : null}
                </Grid>
                <CardContent>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    autoComplete="off"
                    component="form"
                    onSubmit={formik.handleSubmit}
                  >
                    <Box display={"flex"} gap={2}>
                      {colour.map((e, index) => (
                        <Box
                          sx={{
                            height: "20px",
                            width: "20px",
                            transition: "transform 0.2s",
                            cursor: "pointer",
                            "&:hover": {
                              transform: "scale(1.5)",
                            },
                          }}
                          key={`coulour-boxes_${index}`}
                        >
                          <input
                            style={{
                              backgroundColor: `${e.colour}`,
                              height: "100%",
                              width: "100%",
                              appearance: "none",
                              // "&:checked": {
                              //   transform: "scale(1.5)",
                              // },
                            }}
                            onClick={(e) => setCardColor(e.target.value)}
                            name="colour"
                            value={e.colour}
                            type="radio"
                          />
                        </Box>
                      ))}
                      <Box
                        sx={{
                          height: "20px",
                          width: "20px",
                          transition: "transform 0.2s",
                          cursor: "pointer",
                          "&:hover": {
                            transform: "scale(1.5)",
                          },
                          backgroundImage:
                            "conic-gradient(red, yellow, lime, aqua, blue, magenta, red)",
                        }}
                        onClick={(event) => {
                          clickColorPicker(colorPickerDiv, event);
                        }}
                        ref={colorPickerTrigger}
                      ></Box>
                    </Box>
                    {showColorPicker && (
                      <Box sx={{ py: 1, width: "230px" }} ref={colorPickerDiv}>
                        <Chrome
                          inputType="hexa"
                          color={hex}
                          style={{ float: "left" }}
                          onChange={(color) => {
                            setHex(color.hexa);
                            setCardColor(color.hexa);
                          }}
                        />
                      </Box>
                    )}
                    <Droppable droppableId="edit-card" type="FORM">
                      {(provided) => (
                        <Box
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{ width: "100%" }}
                        >
                          <TextField
                            name={"firstName"}
                            InputProps={{
                              style: { fontSize: "1.5rem" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "1.5rem" },
                            }}
                            variant="standard"
                            label={fields["firstName"]?.label}
                            value={formik.values["firstName"]}
                            onChange={(event) => {
                              handleFieldChange("firstName", event);
                              formik.handleChange(event);
                            }}
                            sx={{ width: "95%", m: "5px" }}
                            error={
                              formik.touched["firstName"] &&
                              Boolean(formik.errors["firstName"])
                            }
                            helperText={
                              (formik.touched["firstName"] &&
                                formik.errors["firstName"]) ||
                              " "
                            }
                            onBlur={formik.handleBlur}
                          />
                          <TextField
                            name={"lastName"}
                            InputProps={{
                              style: { fontSize: "1.5rem" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "1.5rem" },
                            }}
                            variant="standard"
                            label={fields["lastName"]?.label}
                            value={formik.values["lastName"]}
                            onChange={(event) => {
                              handleFieldChange("lastName", event);
                              formik.handleChange(event);
                            }}
                            sx={{ width: "95%", m: "5px" }}
                            error={
                              formik.touched["lastName"] &&
                              Boolean(formik.errors["lastName"])
                            }
                            helperText={
                              (formik.touched["lastName"] &&
                                formik.errors["lastName"]) ||
                              " "
                            }
                            onBlur={formik.handleBlur}
                          />
                          <TextField
                            name={"title"}
                            InputProps={{
                              style: { fontSize: "1.5rem" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "1.5rem" },
                            }}
                            variant="standard"
                            label={fields["title"]?.label}
                            value={formik.values["title"]}
                            onChange={(event) => {
                              handleFieldChange("title", event);
                              formik.handleChange(event);
                            }}
                            sx={{ width: "95%", m: "5px" }}
                            error={
                              formik.touched["title"] &&
                              Boolean(formik.errors["title"])
                            }
                            helperText={
                              (formik.touched["title"] &&
                                formik.errors["title"]) ||
                              " "
                            }
                            onBlur={formik.handleBlur}
                          />
                          <TextField
                            name={"company"}
                            InputProps={{
                              style: { fontSize: "1.5rem" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "1.5rem" },
                            }}
                            variant="standard"
                            label={fields["company"]?.label}
                            value={formik.values["company"]}
                            onChange={(event) => {
                              handleFieldChange("company", event);
                              formik.handleChange(event);
                            }}
                            sx={{ width: "95%", m: "5px" }}
                            error={
                              formik.touched["company"] &&
                              Boolean(formik.errors["company"])
                            }
                            helperText={
                              (formik.touched["company"] &&
                                formik.errors["company"]) ||
                              " "
                            }
                            onBlur={formik.handleBlur}
                          />
                          {ordered.includes("email") && (
                            <TextField
                              name={"email"}
                              InputProps={{
                                style: { fontSize: "1.5rem" },
                              }}
                              InputLabelProps={{
                                style: { fontSize: "1.5rem" },
                              }}
                              variant="standard"
                              label={fields["email"]?.label}
                              value={formik.values["email"]}
                              onChange={(event) => {
                                handleFieldChange("email", event);
                                formik.handleChange(event);
                              }}
                              sx={{ width: "95%", m: "5px" }}
                              error={
                                formik.touched["email"] &&
                                Boolean(formik.errors["email"])
                              }
                              helperText={
                                (formik.touched["email"] &&
                                  formik.errors["email"]) ||
                                " "
                              }
                              onBlur={formik.handleBlur}
                            />
                          )}
                          {ordered.includes("phone") && (
                            <TextField
                              name={"phone"}
                              InputProps={{
                                style: { fontSize: "1.5rem" },
                              }}
                              InputLabelProps={{
                                style: { fontSize: "1.5rem" },
                              }}
                              variant="standard"
                              label={fields["phone"]?.label}
                              value={formik.values["phone"]}
                              onChange={(event) => {
                                handleFieldChange("phone", event);
                                formik.handleChange(event);
                              }}
                              sx={{ width: "95%", m: "5px" }}
                              error={
                                formik.touched["phone"] &&
                                Boolean(formik.errors["phone"])
                              }
                              helperText={
                                (formik.touched["phone"] &&
                                  formik.errors["phone"]) ||
                                " "
                              }
                              onBlur={formik.handleBlur}
                            />
                          )}
                          {ordered
                            // ?.filter(
                            //   (field) => field !== "type" && field !== "cardTitle"
                            // )
                            .map((value, key) => {
                              if (
                                value === "firstName" ||
                                value === "lastName" ||
                                value === "title" ||
                                value === "company" ||
                                value === "phone" ||
                                value === "email"
                              ) {
                                return null;
                              } else {
                                return (
                                  <Draggable
                                    draggableId={value}
                                    index={key}
                                    key={value}
                                    isDragDisabled={
                                      value === "firstName" ||
                                      value === "lastName" ||
                                      value === "title" ||
                                      value === "company" ||
                                      value === "phone" ||
                                      value === "email"
                                    }
                                  >
                                    {(provided) => (
                                      <Box
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        ref={provided.innerRef}
                                        sx={{
                                          width: "95%",
                                          m: "5px",
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <MoreVertIcon />
                                        {value !== "documents" ? (
                                          <TextField
                                            name={value}
                                            InputProps={{
                                              style: { fontSize: "1.5rem" },
                                            }}
                                            InputLabelProps={{
                                              style: { fontSize: "1.5rem" },
                                            }}
                                            key={key}
                                            variant="standard"
                                            label={fields[value]?.label}
                                            value={formik.values[value]}
                                            onChange={(event) => {
                                              handleFieldChange(value, event);
                                              formik.handleChange(event);
                                            }}
                                            sx={{ width: "100%", m: "5px" }}
                                            error={
                                              formik.touched[value] &&
                                              Boolean(formik.errors[value])
                                            }
                                            helperText={
                                              (formik.touched[value] &&
                                                formik.errors[value]) ||
                                              " "
                                            }
                                            onBlur={formik.handleBlur}
                                          />
                                        ) : (
                                          <Box>
                                            {fields[value].url ? (
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <TextField
                                                  name={value}
                                                  InputProps={{
                                                    style: {
                                                      fontSize: "1.5rem",
                                                    },
                                                  }}
                                                  InputLabelProps={{
                                                    style: {
                                                      fontSize: "1.5rem",
                                                    },
                                                  }}
                                                  variant="standard"
                                                  label={fields[value]?.label}
                                                  value={formik.values[value]}
                                                  onChange={(event) => {
                                                    handleFieldChange(
                                                      value,
                                                      event
                                                    );
                                                    formik.handleChange(event);
                                                  }}
                                                  sx={{
                                                    width: "95%",
                                                    m: "5px",
                                                  }}
                                                  error={
                                                    formik.touched[value] &&
                                                    Boolean(
                                                      formik.errors[value]
                                                    )
                                                  }
                                                  helperText={
                                                    (formik.touched[value] &&
                                                      formik.errors[value]) ||
                                                    " "
                                                  }
                                                  onBlur={formik.handleBlur}
                                                />
                                                <DeleteForeverRoundedIcon
                                                  sx={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setFields({
                                                      ...fields,
                                                      documents: {
                                                        label: "Documents",
                                                        value: "",
                                                      },
                                                    });
                                                  }}
                                                />
                                              </Box>
                                            ) : (
                                              <Button
                                                variant="outlined"
                                                onClick={() =>
                                                  profileImgRef.current.click()
                                                }
                                              >
                                                <input
                                                  hidden
                                                  ref={profileImgRef}
                                                  type="file"
                                                  // accept="image/*"
                                                  onChange={(e) =>
                                                    handleDocUpload(e)
                                                  }
                                                />
                                                UPLOAD
                                              </Button>
                                            )}
                                          </Box>
                                        )}
                                      </Box>
                                    )}
                                  </Draggable>
                                );
                              }
                            })}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                    <Button
                      variant="contained"
                      sx={{
                        mt: "1rem",
                        backgroundColor: theme.palette.primary.main,
                      }}
                      // onClick={(e) => handleUpdate(e)}
                      type="submit"
                      disabled={
                        fields.firstName.value === "" ||
                        fields.lastName.value === "" ||
                        cropping
                      }
                    >
                      Update
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              gap={3}
              sx={{
                backgroundColor: theme.palette.primary.main,
                filter: "brightness(80%) saturate(80%) grayscale(20%)",
                borderRadius: "2rem",
                outlineWidth: "0.2rem",
                padding: "4rem",
                ...(mobileView && { padding: "1rem" }),
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Add more information:
                </Typography>
              </Grid>

              <Grid container sx={{ m: "auto" }}>
                {Object.keys(additionalInformation)
                  .filter((key) => {
                    return key != "firstName" && key != "lastName";
                  })
                  .map((key, index) => {
                    const item = additionalInformation[key];
                    return (
                      <Grid
                        item
                        xs={4}
                        lg={3}
                        xl={2}
                        className={"information-button-base".concat(
                          Object.keys(fields).includes(key) === true
                            ? " active"
                            : ""
                        )}
                        onClick={() =>
                          handleItemClick(
                            key,
                            additionalInformation[key]?.label
                          )
                        }
                        key={`addidional-toggles_button_${index}`}
                      >
                        {item.svg}
                        <p>{item?.label}</p>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DragDropContext>
    </>
  );
};

export default EditCard;
