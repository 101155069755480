import { useNavigate, Link, useLocation } from "react-router-dom";
import { useState, useRef } from "react";
import {
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Button,
  CardActions,
  Box,
  CardMedia,
  Fab,
  Slide,
  Dialog,
} from "@mui/material";
import BigPlus from "./bigPlus";
import avatarPlaceholderImg from "../../images/cards_page/avatar.png";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DeleteCard from "./DeleteCardModal";
import useMediaQuery from "src/hooks/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const TCard = (props) => {
  const location = useLocation();
  const [cardData, setCardData] = useState(props.data ? props.data : null);
  const [showDelete, setShowDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const containerRef = useRef(null);
  let loc = location.pathname;
  const navigate = useNavigate();
  const mobileView = useMediaQuery(0, "480px");
  const theme = useTheme();

  return (
    <>
      <Card
        variant="greenborder-column"
        sx={{
          height: "315px",
          width: "235px",
          minHeight: "201px",
          minWidth: "150px",
          borderColor: cardData?.cardColor || theme.palette.primary.main,
          justifyContent: "space-between",
          overflow: "hidden",
          position: "relative",
          mr: "2rem",
          ...(mobileView && { height: "201px", width: "150px" }),
        }}
        onMouseEnter={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
        ref={containerRef}
      >
        {cardData ? (
          <Slide
            in={showDelete}
            direction="left"
            container={containerRef.current}
          >
            <Fab
              aria-label={"delete card"}
              color="error"
              sx={{ position: "absolute", left: "70%", top: 8 }}
              onClick={() => setShowModal(true)}
            >
              <DeleteForeverRoundedIcon fontSize="large" />
            </Fab>
          </Slide>
        ) : null}
        <CardActionArea
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
          onClick={() => props.action(props.id)}
        >
          {cardData ? (
            <CardMedia
              component="img"
              image={
                cardData?.profile ? cardData.profile : avatarPlaceholderImg
              }
              sx={{
                height: "214px",
                width: "235px",
                display: "flex",
                ...(mobileView && { height: "120px", width: "150x" }),
              }}
            />
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                ["@media (min-width:960px)"]: {
                  my: "27%",
                },
              }}
            >
              <BigPlus color="primary" />
              <Typography variant="h3" pt={1}>
                Add Card
              </Typography>
            </Box>
          )}
          {cardData ? (
            <CardContent variant="cardPage">
              <Typography
                variant="h3"
                sx={{
                  ...(!mobileView && { pt: 1 }),
                  ...(mobileView && { mb: 0 }),
                }}
              >
                {cardData.cardTitle.value !== ""
                  ? cardData.cardTitle.value
                  : "Unnamed Card"}
              </Typography>
            </CardContent>
          ) : null}
        </CardActionArea>

        {cardData ? (
          <>
            <CardActions
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                p: 0,
                pb: 0.5,
              }}
            >
              <Button
                sx={{
                  m: 0,
                  py: 0.2,
                  px: 1,
                  ...(mobileView && {
                    width: "35%",
                    padding: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }),
                }}
                variant="outlined"
                onClick={() => navigate(`${cardData.id}/edit`)}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                sx={{
                  m: 0,
                  py: 0.2,
                  px: 1,
                  backgroundColor:
                    cardData?.cardColor || theme.palette.primary.main,
                  ...(mobileView && {
                    width: "48%",
                    padding: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }),
                }}
              >
                <Link
                  to={`/card/${cardData.id}`}
                  target={"_blank"}
                  rel={"noopener noreterrer"}
                  style={{
                    margin: "auto",
                    padding: 1.2,
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  Preview
                </Link>
              </Button>
            </CardActions>
          </>
        ) : null}
      </Card>

      <Dialog open={showModal}>
        {showModal ? (
          <DeleteCard
            setShowModal={setShowModal}
            cardData={cardData}
            fetchCards={props.fetchCards}
          />
        ) : null}
      </Dialog>
    </>
  );
};

export default TCard;
