import React, { useContext, useEffect } from "react";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import "./OrderCard.css";
// import SideNavBar from "../../components/Side_NavBar/SideNavBar";
import OderCardTemplate from "./OderCardTemplate";
import OderCardBusinessTemplate from "./OderCardBusinessTemplate";

import { useOutletContext } from "react-router-dom";
import { OrderCardTemplateDets } from "./CardData.js";
import OcSelectCard from "./OcSelectCard";
import { Box, Typography } from "@mui/material";
import useMediaQuery from "src/hooks/useMediaQuery";

const OrderCard = () => {
  const { user, setTitle } = useOutletContext();
  const navigate = useNavigate();
  const context = useContext(MyContext);
  const selectedCard = context.selectedCardForOrder;
  const mobileView = useMediaQuery(0, "480px");

  useEffect(() => {
    setTitle("Order card");
  }, []);

  const handleOrderPlanSelected = (type) => {
    // console.log(type);
    context.setCrdOrderType(type);
    if (selectedCard) {
      navigate(`/order/card/${type}/${selectedCard?.id}`);
    } else {
      navigate(`/order/card/${type}`);
    }
  };

  return (
    <Box sx={{ color: "hsla(0, 0%, 20%, 1)" }}>
      <Box
        sx={{
          minHeight: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
        }}
      >
        {/* second part in the page  */}
        <Box>
          <Typography
            variant="paragraph"
            sx={{
              fontSize: "2rem",
              fontWeight: "500",
              letterSpacing: "0.05rem",
              marginBottom: "2rem",
              ...(mobileView && { marginLeft: "1rem", fontSize: "3vw" }),
            }}
          >
            Select your card:
          </Typography>
          <OcSelectCard />
        </Box>

        {/* third part in the page  */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            // marginRight: "15rem",
            width: "85vw",
            gap: 2,
            mb: 2,
            overflowX: "auto",
            scrollbarWidth: "thin",
            ["&::-webkit-scrollbar"]: {
              display: "none",
            },
            ...(mobileView && { width: "95vw" }),
          }}
        >
          <OderCardTemplate action={handleOrderPlanSelected} />
          <OderCardBusinessTemplate details={OrderCardTemplateDets[3]} />
        </Box>
      </Box>
    </Box>
  );
};

export default OrderCard;
